import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Modal from '../modal/modal2';
import { useTemplateLevelsQuery } from '../../services/enumApi';
import SelectField from '../Input/select-field';
import TextField from '../Input/text-field';

const SaveAsDialog = (props: any) => {

const { showSaveAsModal,
        handleCloseSaveAsModal,
        handleOkSaveAsModal,
        onSaveAsFormSubmit,
        saveTemplateName,
        setSaveTemplateName,
        saveTemplateNameError,
        templateLevel,
        setTemplateLevel,
        forView
     } = props;
     
const { data: templateLevels, isLoading: boreholeStatusesIsLoading } = useTemplateLevelsQuery(forView ?? false);

useEffect(() => {  
  if (templateLevels?.length) {
    setTemplateLevel(templateLevels[0]?.value);
  }
}, [templateLevels?.length]);

const onOnSaveAsFormSubmit =(e: any) => {
  e.preventDefault();
  onSaveAsFormSubmit();
}

return (
  <ReactModal 
    isOpen={showSaveAsModal}
    ariaHideApp={false}
    contentLabel="Save as name"
    >
    <Modal 
      closeModal={handleCloseSaveAsModal} 
      handleOkModal={handleOkSaveAsModal}        
      title="Save as"
    >
        <form onSubmit={onOnSaveAsFormSubmit}>
          <div className="columns">
            <div className="column">              
              <TextField type="text" label="Name" errors={saveTemplateNameError} value={saveTemplateName} onChange={setSaveTemplateName} />

              <SelectField
                  label="Level"
                  values={templateLevels}
                  value={templateLevel}
                  onChange={setTemplateLevel} />        
            </div>                   
          </div>
          <button type="submit" style={{display: "none"}}></button>
        </form>
      </Modal>
  </ReactModal>
)}

export default SaveAsDialog;