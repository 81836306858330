import OpenSeadragon from "openseadragon";
import OpenSeaDragon from "openseadragon";
import React, { useEffect, useRef, useState } from "react";
import auth from '../auth';

const OpenSeaDragonViewer = ({ image, 
                              className, 
                              id, 
                              moved, 
                              zoom, 
                              center, 
                              ctrlKeyPressed, 
                              mouseWheelZoom, 
                              trackHeight, 
                              updateDepth, 
                              zoomOutPastMaximum,                         
                              startDepth,
                              shouldUpdateDepth, 
                              setShouldUpdateDepth,
                              filter,
                              setMetaData,
                              metaData,
                              getDirect,
                              individualImageHeight,
                              pixelWidth,
                              imageType,
                              showSeaDragon } : any) => {

  const [viewer, _setViewer] = useState<any>(null);
  const [tooltipDepth, setTooltipDepth] = useState<any>();
  const [overlayTop, setOverlayTop] = useState<number>(0);
  const [overlayLeft, setOverlayLeft] = useState<number>(0);
  const [overlayHidden, setOverlayHidden] = useState<boolean>(true);
  const [reachedMaxZoom, setReachedMaxZoom] = useState<boolean>(false);
  
  useEffect(() => {
    if (showSeaDragon) {
      console.log(imageType + " showSeaDragon")
      setReachedMaxZoom(true);
    }
  },[showSeaDragon]);

  const viewerRef = useRef(viewer);
  
  const imageHeight = individualImageHeight;

  const imageHeightRef = useRef(individualImageHeight);
  const pixelWidthRef = useRef(pixelWidth);

  const ref = useRef<any>();
        
  const setViewer = (data: any) => {
    viewerRef.current = data;
    _setViewer(data);
  };
  
  const ignoreMovementRef = useRef<boolean>(true);
  const setIgnoreMovement = (data: any) => {
    ignoreMovementRef.current = data;
  };

  const canvasDraggingRef = useRef<boolean>(false);
  const setCanvasDraggingRef = (data: boolean) => {
    canvasDraggingRef.current = data;
  };

  useEffect(() => {
    if (image && viewer) {
        viewer.open(image.source);
    }
  }, [image]);

  useEffect(() => {    
    if (image && viewer && zoom >= 1 && ignoreMovementRef.current) {      
        viewer.viewport.zoomTo(zoom);
        if (zoom > 1 && reachedMaxZoom) {
          setReachedMaxZoom(false);
        }
    }
  }, [zoom]);

  useEffect(() => {    
    if ((shouldUpdateDepth || startDepth) && image && viewer) {      

    // given a depth find the offset
    const previousImages = metaData?.imageSizes.filter((i: any) => i.section < startDepth);
        let offsetY = 0;
        previousImages.forEach((imageSize: any, index: number) => {          
            offsetY += imageSize.pixelWidth;          
        });

        
      var newY = offsetY; //(imageHeight * (startDepth + 1)) - (imageHeight / 2);      
      var newCenter = viewer.viewport.imageToViewportCoordinates(0, newY);
      var bounds = viewer.viewport.getBounds();
      //var newCenter = viewer.viewport.imageToViewerElementCoordinates(new OpenSeaDragon.Point(10000, newY));
      
      newCenter.y += bounds.height / 2;
      newCenter.x = 0.5;
      viewer.viewport.panTo(newCenter);
      viewer.viewport.applyConstraints();
      if (shouldUpdateDepth) {        
        setShouldUpdateDepth(false);
      }
    }
  }, [startDepth, image, viewer, shouldUpdateDepth]);
  
  useEffect(() => {  
    if (image && viewer && center) {
        viewer.viewport.panTo(center);
    }
  }, [JSON.stringify(center)]);

const InitOpenseadragon = () => {  
    viewer && viewer.destroy();

    let seadragonOptions: OpenSeadragon.Options = {
      id: id,
      prefixUrl: "https://cdnjs.cloudflare.com/ajax/libs/openseadragon/2.4.2/images/",
      loadTilesWithAjax: !getDirect,
      crossOriginPolicy: false,
      animationTime: 0.05,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: Infinity,        
      visibilityRatio: 1,
      zoomPerScroll: 1.2,
      showNavigator: true,
      navigatorPosition: "TOP_RIGHT",
      navigatorOpacity: 0.5,
      navigatorDisplayRegionColor: "white",
      defaultZoomLevel: 1,
      minZoomLevel: 1,
      imageSmoothingEnabled: false,     
      //homeFillsViewer: true, 
      // navigatorLeft: 600,
      // navigatorTop: 0,
      // navigatorHeight: 150,
      // navigatorWidth: 100,
      gestureSettingsMouse: { scrollToZoom: mouseWheelZoom }   
    };

    if (!getDirect) {
      seadragonOptions.ajaxWithCredentials = !getDirect;
      seadragonOptions.ajaxHeaders = {'authorization': `Bearer ${auth.getTokenForImages()}`};
      seadragonOptions.crossOriginPolicy = "use-credentials"
    }

    let newViewer: any = OpenSeaDragon(seadragonOptions);

    newViewer.addHandler("open", function(event: any) {      
      const imageHeightInViewportCoordinates = event.eventSource.viewport.imageToViewportCoordinates(0, event.source.Image.Size.Height).y;
      const homeBounds = event.eventSource.viewport.getHomeBounds();
      if (imageHeightInViewportCoordinates > homeBounds.height) {
        // only start at top of cores if all cores are larger than the viewer height, 
        // otherwise just do the default of starting in the center of the viewport
        var box = event.eventSource.viewport.getHomeBounds();
        box.x = homeBounds.x;
        box.y = homeBounds.height / 2;
        box.width = homeBounds.width;
        box.height = 0;
    
        event.eventSource.viewport.fitBounds(box, true);
        updateDepthFromViewportBounds(box);
      } else {
        updateDepthFromViewportBounds(homeBounds);
      }
    });

    newViewer.addHandler("canvas-drag", function (event: any) {
      setCanvasDraggingRef(true);
    });

    newViewer.addHandler("canvas-drag-end", function (event: any) {
      setCanvasDraggingRef(false);
      //setIgnoreMovement(true);
    });

    newViewer.addHandler("full-screen", function (event: any) {
      newViewer.gestureSettingsMouse.scrollToZoom = event.fullScreen;
      //setIgnoreMovement(true);
    });

    // newViewer.addHandler("canvas-enter", function (event: any) {
    //   console.log("canvas-enter");
    //   setIgnoreMovement(false);
    // });
    // newViewer.addHandler("canvas-exit", function (event: any) {
    //   console.log("canvas-exit");
    //   setIgnoreMovement(true);      
    // });
    
    newViewer.addHandler("pan", function (event: any) {      
      //console.log("pan " + imageType, "ignore: " + ignoreMovementRef.current)
      if (!ignoreMovementRef.current) {
        var bounds = event.eventSource.viewport.getBounds();
        updateDepthFromViewportBounds(bounds);
        moved({zoom: event.eventSource.viewport.getZoom(), center: event.eventSource.viewport.getCenter()});
      }
    });

    // newViewer.addHandler("zoom", function (event: any) {
    //   if (!ignoreMovementRef.current) {
    //     moved({zoom: event.eventSource.viewport.getZoom(), center: event.eventSource.viewport.getCenter()});
    //   }
    // });
    // newViewer.addHandler("navigator-drag", function (event: any) {
    //     moved({zoom: event.eventSource.viewport.getZoom(), center: event.eventSource.viewport.getCenter()});
    // });    
   
    // newViewer.addHandler("viewport-change", function (event: any) {
    //   debugger;
    //   if (ignoreViewportChange) {
    //     return;
    //   }

    //   var bounds = event.eventSource.viewport.getBounds();
    //   updateDepthFromViewportBounds(bounds);

    //   // if (!ignoreMovementRef.current) {
    //   //   console.log("viewport-change", imageType)
    //   //   const zoom = event.eventSource.viewport.getZoom();
    //   //   if (zoom === 0.5) {
    //   //     onZoomOutPastMaximum();
    //   //   } else {
    //   //     moved({zoom: zoom, center: event.eventSource.viewport.getCenter()}, false);
    //   //   }
    //   // }
    // });

    newViewer.addHandler("zoom", function (event: any) {           
      //console.log("zoom " + imageType, "ignore: " + ignoreMovementRef.current)
      var zoom = event.eventSource.viewport.getZoom();            
      if (zoom === 0.5) {
        onZoomOutPastMaximum();
      } else {
        var bounds = event.eventSource.viewport.getBounds();
        updateDepthFromViewportBounds(bounds);
        if (!ignoreMovementRef.current) {       
          moved({zoom: event.eventSource.viewport.getZoom(), center: event.eventSource.viewport.getCenter()});
        }
      }
    });
    
    // newViewer.addHandler("constrain", function (event: any) {      
    //   console.log("constrain");
    //   var bounds = event.eventSource.viewport.getBounds();
    //   updateDepthFromViewportBounds(bounds);
    // });

    // newViewer.addHandler("update-viewport", function (event: any) {
    //   console.log("update-viewport");
    //   var bounds = event.eventSource.viewport.getBounds();
    //   updateDepthFromViewportBounds(bounds);
    // });

    setViewer(newViewer);

    // var bounds = newViewer.viewport.getBounds();
    // updateDepthFromViewportBounds(bounds);
  };

  const calculateSectionNumber = (y: number) => {
    let pixelCount = 0;
    let imageIndex = 0;
    let lastSection = 0;
    
    // if (y < 0) {      
    //   const firstImageSize = metaData.imageSizes[0];
    //   lastSection = firstImageSize.section + (y / individualImageHeight);    
    //   return lastSection + 0.5;
    // }

    if (y < 0) {      
        const firstImageSize = metaData?.imageSizes[0];
        lastSection = firstImageSize?.section - 1;
    }

    while (pixelCount < y && imageIndex < metaData?.imageSizes.length) {
      const imageSize = metaData.imageSizes[imageIndex];
      if (imageSize == null) {
        debugger;
      }
      pixelCount += individualImageHeight;   
      lastSection = imageSize.section;
      imageIndex++;
    }

    const remainder = y - pixelCount;
    if (remainder < 0) {
      // let imageSize = metaData.imageSizes[imageIndex];
      // if (imageSize == null) {
      //   return lastSection;  
      // }

      return lastSection + (remainder / individualImageHeight) + 0.5;
    } else {
      const lastImageSize = metaData?.imageSizes[metaData.imageSizes.length - 1];
      return lastSection + (remainder / individualImageHeight) + 0.5;
    }    
  };

  const updateDepthFromViewportBounds = (bounds: any) => {
    if (!updateDepth || bounds?.width > 1) {
      return;
    }
    
    var start = bounds.y;
    var height = bounds.height;

    var imagePoint = viewerRef.current.viewport.viewportToImageCoordinates(0, start);

    
    // let offsetY = 0;
    // previousImages.forEach((imageSize: any, index: number) => {
    //   if (index % 2 == 0) {
    //     offsetY += roundHalf((originalImageWidth / imageSize.pixelHeight) * imageSize.pixelWidth * 0.5) + 2;
    //   }
    // });

    var calculatedStart = calculateSectionNumber(imagePoint.y); // (((imagePoint.y + (imageHeight / 2)) / imageHeight) - 1);

    var imagePoint2 = viewerRef.current.viewport.viewportToImageCoordinates(0, height);

    var lastSection = calculateSectionNumber(imagePoint.y + imagePoint2.y);

    var calculatedDepth = lastSection - calculatedStart; //imagePoint2.y / imageHeight;
    
    //if (calculatedStart > 0) {
      updateDepth(calculatedStart, calculatedStart + calculatedDepth);     
    //}
  };

  useEffect(() => {
    if (imageHeight) {
      InitOpenseadragon();
      imageHeightRef.current = imageHeight;
      pixelWidthRef.current = pixelWidth;
      return () => {
          viewer && viewer.destroy();
      };      
    }
  }, [imageHeight]);

  useEffect(() => {
    if (viewer) {
      viewer.gestureSettingsMouse.scrollToZoom = mouseWheelZoom;
    }
  }, [mouseWheelZoom]);

  useEffect(() => {
    if (viewer && !mouseWheelZoom) {
      viewer.gestureSettingsMouse.scrollToZoom = ctrlKeyPressed;
    }
  }, [ctrlKeyPressed]);

  const onZoomOutPastMaximum = () => {
    //work out depth of top of image
    var point = new OpenSeaDragon.Point(0, 0);
    var viewportPoint = viewerRef.current.viewport.pointFromPixel(point);      
    var imagePoint = viewerRef.current.viewport.viewportToImageCoordinates(viewportPoint.x, viewportPoint.y);
    //var calculatedDepth = imagePoint.y < 0 ? 0 : Math.floor(imagePoint.y / imageHeight) + (imagePoint.x / pixelWidth);
    var calculatedDepth = calculateSectionNumber(imagePoint.y)

    zoomOutPastMaximum(calculatedDepth);
  };


  const onWheel = (event: any) => {    
    if (!viewer) {
      return;
    }
    
    if (mouseWheelZoom || ctrlKeyPressed) {
      if (reachedMaxZoom && event.deltaY > 0) {       
        
        console.log("onZoomOutPastMaximum from Sea Dragon");
        onZoomOutPastMaximum();
        //setReachedMaxZoom(false);
      } else {
        const zoom = viewer.viewport.getZoom();
        if ((Math.round(zoom * 1000) / 1000) === 1) {        
          console.log(imageType + " setReachedMaxZoom", true);
          setReachedMaxZoom(true);
        } else {
          console.log(imageType + " setReachedMaxZoom", false);          
          setReachedMaxZoom(false);
        }
      }
    }

    if (!mouseWheelZoom && !ctrlKeyPressed) {
      if (event.deltaY < 0) {
        panBy(-0.1);
      } else {
        panBy(0.1);
      }
    }
  }

const zoomBy = (zoom: number) => {
  var viewport = viewerRef.current?.viewport;
  if (viewport) {
    viewport.zoomBy(zoom);
    viewport.applyConstraints();
  }
};

const panBy = (pan: number) => {
  var viewport = viewerRef.current?.viewport;
  if (viewport) {
    viewport.panBy({x:0, y:pan});
    viewport.applyConstraints();
  }
};

const onMouseOver = () => {
  setIgnoreMovement(false);
  setOverlayHidden(false);
};

const onMouseOut = () => {
  if (!canvasDraggingRef.current) {
    setIgnoreMovement(true);
  }
  setOverlayHidden(true);
};

const onMouseMove = (e: any) => {  
  var bounds = ref.current.getBoundingClientRect();
  const posX = e.pageX - bounds.left;
  const posY = e.pageY - bounds.top;
  setOverlayTop(posY);
  setOverlayLeft(posX);
};

useEffect(() => {
  if (!viewerRef?.current) {
    return;
  }

  new OpenSeaDragon.MouseTracker({
    element: id,
    moveHandler: function(e: any) {      
        if (!viewerRef?.current) {
          return;
        }
        var viewportPoint = viewerRef.current.viewport.pointFromPixel(e.position);      
        var imagePoint = viewerRef.current.viewport.viewportToImageCoordinates(viewportPoint.x, viewportPoint.y);
        var sectionNumber = Math.floor(imagePoint.y / imageHeightRef.current) + 1;
        var calculatedDepth = Math.floor(imagePoint.y / imageHeightRef.current) + (imagePoint.x / pixelWidthRef.current);
        var stringDepth = (Math.round(calculatedDepth * 1000) / 1000).toFixed(3);
        //setTooltipDepth(stringDepth);        
        setMetaData(sectionNumber,  (imagePoint.x / pixelWidthRef.current));
    }
  });
  
}, [viewerRef?.current]);

return (  
  <div className={className} onMouseOver={onMouseOver} onMouseOut={onMouseOut} ref={ref} >
    {/* {ctrlKeyPressed && <span>ctrl key</span>}    
     <label className="checkbox">
      <input type="checkbox" checked={mouseWheelZoom} onChange={(e: any) => setMouseWheelZoom(e.target.checked)} />  Mouse Wheel Zooms
    </label>  */}
    <div 
        onWheel={onWheel}
        onMouseMove={onMouseMove}
        id={id}
        style={{
          filter: filter,
          height: trackHeight + "px",
          width: "600px"
        }}
    >
    </div>
    <div id={`overlay1-${id}`} style={{backgroundColor: "#ffffffA0", padding: "1rem", borderRadius: "5px", position: "absolute", top: overlayTop + 15, left: overlayLeft + 10, zIndex: 100, whiteSpace: "nowrap"}} hidden={true}>
      <p>Depth: {tooltipDepth}m</p>
    </div>
  </div>
  );
};

export default  OpenSeaDragonViewer;