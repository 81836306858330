import React, { useState, useEffect, useCallback } from 'react';
import fetchProgress from 'fetch-progress';
import TrackData from '../track-data/TrackData';
import { useGetBoreholeSummaryQuery, useExportFileMutation } from '../../services/boreholeApi';
import { confirmAlert } from 'react-confirm-alert'; 
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import getConfig from "../../app/config";
import auth from '../../components/auth';

import {   
  getBoreholesByProject,
  } from "../../services/boreholesSlice";

import { getTemplates, 
         getTemplate, 
         //getMultipleBoreholeTrackData,
         addCurve,
         updateCurve,
         updateCurves,         
         addTemplate, 
         updateTemplate,  
         deleteTemplate,                  
         setShowGridlines,
         setShowValueAxisAnnotation,
         setScaleType,
         setTrackWidth,
         setSelectedBuiltInTemplateId, 
         setSelectedUserTemplateId,
         setSelectedBoreholeTemplateId,
         setSelectedProjectTemplateId,
         setSelectedClientTemplateId,
         setSelectedTemplateId,
         setNextTemplateId, 
         clearNotSaved,
         addGraphTracks, 
         addImageTracks,
         removeTrack,
         updateTrackOrder,        
         getTrackData, 
         getMultipleBoreholeTrackDataForTrackTypes,
         getMultipleBoreholeTrackDataForTemplate,
         getMultipleBoreholeTrackDataForView,
         getTrackDataForTrackTypes,
         addCumulativeTrack,
         addTadpoleTrack,
         addLithologyTrack,
         addTextTrack,
         setDepthMajorIntervals,
         setShowDepthGridlines,         
         setDepthMinorIntervals, 
         setShowDepthMinorIntervals,
         clearTemplatesLoaded,
         scrollTrackIntoView,
         scrollAdjustmentIntoView,
         setTrackCollapsed,
         setDepthUnit,
         changeTemplateLevel,
         clearTemplate,
         setViewer,
         changeDataValue,
         saveLithologyData,
         changeDepth,         
         addDataValue, 
         deleteDataValue,
         setTrackEditMode,
         addNewTemplate,
         clearTrackData,
         clearUrlTemplateId,
         addTextValue,
         changeTextData,
         saveTextData,
         addStatisticsTracks,
         deleteStatisticsTrack, 
         addHorizontalBarGraphTracks} from './templateSlice';
import { updateGroupTrackOrder, 
         updateGroupOrder, 
         clearView, 
         addBorehole,
         addBoreholes,
         addTracksToGroups,
         addGraphTracksToGroups,
         addImageTracksToGroups,
         addCumulativeTracksToGroups,
         addTadpoleTracksToGroups,
         addLithologyTracksToGroups,
         addDescriptionTracksToGroups,
         getViews,
         addView,
         setSelectedUserViewId,
         setSelectedClientViewId,
         setSelectedViewId,
         setNextViewId,
         getView,
         updateView,
         setGroupTrackCollapsed,
         addViewCurve,
         updateViewCurves,
         deleteTrack,
         changeViewLevel,
         setViewDepthMajorIntervals,
         setViewShowDepthGridlines,         
         setViewDepthMinorIntervals, 
         setViewShowDepthMinorIntervals,
         setViewShowValueAxisAnnotation,
         setViewTrackWidth,
         setGroupCollapsed
 } from "../../services/viewsSlice";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import Loader from '../../components/loader';
import Errors from '../../components/errors';
import { useTrackMutation, useGenerateMutation } from '../../services/pdfApi';
import { usePaperSizesQuery } from '../../services/enumApi';
import { useTrackTypesQuery, useImageTypesQuery, useTrackCategoriesQuery, useTrackTypesHierarchyQuery } from '../../services/trackTypeApi';
import PrintDialog from '../../components/log-viewer/print-dialog';
import SubMenu from '../../components/sub-menu';
import SaveAsDialog from '../../components/dialogs/save-as-dialog';
import NotSavedDialog from '../../components/dialogs/not-saved-dialog';
import AddTrackPanel from '../../components/panels/add-track-panel';
import BoreholeCorrelationPanel from '../../components/panels/group/borehole-correlation-panel';
import TrackOrderPanel from '../../components/panels/order/track-order-panel';
import './borehole-log-viewer.scss';
import GraphAdjustments from '../../components/panels/graph-adjustments/graph-adjustments';
import DepthPanel from '../../components/panels/log-viewer/depth-panel';
import PrintPanel from '../../components/panels/log-viewer/print-panel';
import TemplatesPanel from '../../components/panels/log-viewer/templates-panel';
import InfoPanel from '../../components/panels/log-viewer/info-panel';
import XAxisPanel from '../../components/panels/log-viewer/x-axis-panel';
import ExportPanel from '../../components/panels/log-viewer/export-panel';
import LeftSideMenu from '../../components/side-menu/log-viewer-left-side-menu';
import DepthData from '../../components/panels/log-viewer/depth-data';
import AddStatisticsPanel from '../../components/panels/add-statitics-panel';
import ViewsPanel from '../../components/panels/log-viewer/views-panel';

const BoreholeLogViewer = ({setBorehole}: any) => {    

    let { boreholeId, clientId, projectId, templateId, viewId } = useParams<any>();
    let history = useHistory();

    const [skipLoad, setSkipLoad] = useState(true);
    const [childLoading, setChildLoading] = useState(false);        
    const [startDepth, setStartDepth] = useState<number | null>(null);
    const [endDepth, setEndDepth] = useState<number | null>(null);
    // const [depthTrackStartDepth, setDepthTrackStartDepth] = useState<number | null>(null);
    // const [depthTrackEndDepth, setDepthTrackEndDepth] = useState<number | null>(null);
    //const [minDepth, setMinDepth] = useState<number | undefined>(undefined);
    //const [maxDepth, setMaxDepth] = useState<number | undefined>(undefined);
    const [selectedBuiltInTemplateIdValue, setSelectedBuiltInTemplateIdValue] = useState<any>(null);
    const [selectedUserTemplateIdValue, setSelectedUserTemplateIdValue] = useState<any>(null);
    const [selectedBoreholeTemplateIdValue, setSelectedBoreholeTemplateIdValue] = useState<any>(null);
    const [selectedProjectTemplateIdValue, setSelectedProjectTemplateIdValue] = useState<any>(null);
    const [selectedClientTemplateIdValue, setSelectedClientTemplateIdValue] = useState<any>(null);
    const [currentTemplateLevel, setCurrentTemplateLevel] = useState<string | null>(null);
    const [byBoreholeDepth, setByBoreholeDepth] = useState<boolean>(true);
    const [depthUnitChanged, setDepthUnitChanged] = useState<boolean>(false);

    const [currentViewLevel, setCurrentViewLevel] = useState<string | null>(null);
    const [selectedClientViewIdValue, setSelectedClientViewIdValue] = useState<any>(null);
    const [selectedUserViewIdValue, setSelectedUserViewIdValue] = useState<any>(null);
    const [showSaveViewAsModal, setShowSaveViewAsModal] = useState<boolean>(false);
    const [saveViewName, setSaveViewName] = useState<string>("");
    const [saveAsViewLevel, setSaveAsViewLevel] = useState<any>();  
    const [saveViewNameError, setSaveViewNameError] = useState<any>(null);
    
    const [depthType, setDepthType] = useState<string>("Borehole");
    const [saveTemplateName, setSaveTemplateName] = useState<string>("");
    const [saveAsTemplateLevel, setSaveAsTemplateLevel] = useState<any>();  
    const [saveTemplateNameError, setSaveTemplateNameError] = useState<any>(null);
    const [moveTemplateError, setMoveTemplateError] = useState<any>(null);
    const [moveViewError, setMoveViewError] = useState<any>(null);
    const [showSaveAsModal, setShowSaveAsModal] = useState<boolean>(false);
    const [showNotSavedModal, setShowNotSavedModal] = useState<boolean>(false);
    
    const [selectedTrackType, setSelectedTrackType] = useState<any>();
    const [checkedTrackTypes, setCheckedTrackTypes] = useState<Array<string>>([]);
    const [selectedTemplateTrackType, setSelectedTemplateTrackType] = useState(0);
    const [selectedTrackCategory, setSelectedTrackCategory] = useState<any>(null);
    const [checkedImageTypes, setCheckedImageTypes] = useState<Array<number>>([]);
    const [checkedLithologyTypes, setCheckedLithologyTypes] = useState<Array<number>>([]);
    const [checkedTextTypes, setCheckedTextTypes] = useState<Array<number>>([]);
    const [editMode, setEditMode] = useState<boolean>(false);

    const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
    const [isPrinting, setIsPrinting] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [exportCompleted, setExportCompleted] = useState<boolean>(false);
    const [exportUrl, setExportUrl] = useState<string>();
    const [exportProgress, setExportProgress] = useState<any>();
    const [exportFileName, setExportFileName] = useState<string>();
    
    const [showRightSidePanel, setShowRightSidePanel] = useState<boolean>(false);    
    const [showAddTrackPanel, setShowAddTrackPanel] = useState<boolean>(false);
    const [showReorderPanel, setShowReorderPanel] = useState<boolean>(false);
    const [showAdjustmentsPanel, setShowAdjustmentsPanel] = useState<boolean>(false);
    const [showAddStatisticsPanel, setShowAddStatisticsPanel] = useState<boolean>(false);
    const [showAddBoreholePanel, setShowAddBoreholePanel] = useState<boolean>(false);
    const [selectedTrack, setSelectedTrack] = useState<any>();
    const [selectedGroupId, setSelectedGroupId] = useState<any>();

    const [showLeftSidePanel, setShowLeftSidePanel] = useState<boolean>(true);
    const [showDepthPanel, setShowDepthPanel] = useState<boolean>(false);
    const [showPrintPanel, setShowPrintPanel] = useState<boolean>(false);
    const [showTemplatesPanel, setShowTemplatesPanel] = useState<boolean>(true);
    const [showViewsPanel, setShowViewsPanel] = useState<boolean>(true);
    const [showInfoPanel, setShowInfoPanel] = useState<boolean>(false);
    const [showXAxisPanel, setShowXAxisPanel] = useState<boolean>(false);
    const [showExportPanel, setShowExportPanel] = useState<boolean>(false);
    const [metaDataDepth, setMetaDataDepth] = useState<any>();
    const [createPdfError, setCreatePdfError] = useState<any>();
    const [createPdfProgress, setCreatePdfProgress] = useState<any>();

    const [selectedLithologyId, setSelectedLithologyId] = useState<any>();
    const [trackLegendImage, setTrackLegendImage] = useState<any>();
    
    const selectAccount = (state: RootState) => state.account;
    const { 
      connection,
      connected
    } = useSelector(selectAccount);

    const selectBorehole = (state: RootState) => state.borehole;
    const {       
      boreholesByProject
    } = useSelector(selectBorehole);

    useEffect(() => {
      dispatch(setViewer("Borehole"));
      dispatch(clearTemplate());
      dispatch(clearView());
      dispatch(clearTrackData());
      dispatch(getBoreholesByProject());
      return function cleanup() {
        dispatch(clearTemplate());
      };
    },[]);

    useEffect(() => {        
        const subscribeToProgress = async () => {
            if (!connection) {
              return;
            }
            
            connection.off("GenerateTrackPdfComplete");
            connection.off("SendCreateTrackPdfProgress");
            connection.off("SendExportDataProgress");
            connection.off("SendDownloadExport");
            connection.on("GenerateTrackPdfComplete", (url: any, fileName: any) => {
              const mininmumPercentage = 90;
              const maxinmumPercentage = 100;
              downloadFile(url, fileName, 5,  (percentage: number) => {                
                var progressPercentage = mininmumPercentage + ((maxinmumPercentage - mininmumPercentage) * percentage / 100);
                setCreatePdfProgress({progress: progressPercentage, message: "Downloading...", failed: false});
              }, () => setIsPrinting(false));
            });

            connection.on("SendCreateTrackPdfProgress", (progress: number, message: string, failed: boolean) => {
              setCreatePdfProgress({progress, message, failed});
              if (failed) {
                setCreatePdfError(message);
                setIsPrinting(false);
              }
            });

            connection.on("SendExportDataProgress", (progress: number, message: string, failed: boolean) => {
              setExportProgress({progress, message, failed});              
            });

            connection.on("SendDownloadExport", (url: any, fileName: any) => {
              const mininmumPercentage = 90;
              const maxinmumPercentage = 100;
              
              setExportFileName(fileName);
              const fileUrl = downloadFile(url, fileName, 6, (percentage: number) => {                
                var progressPercentage = mininmumPercentage + ((maxinmumPercentage - mininmumPercentage) * percentage / 100);
                setExportProgress({progress: progressPercentage, message: "Completed exporting. Downloading...", failed: false});
              }, () => {
                setIsExporting(false);
                setExportCompleted(true);
               })
              
              setExportUrl(fileUrl);
            });
        }
    
        if (connected && connection?.state === "Connected") {      
          subscribeToProgress();
        }
      
    }, [connected, connection?.state]);

    useEffect(() => {
      if (!isPrinting) {
        setShowPrintModal(false);
      }
    },[isPrinting])
  
    const apiUrl = getConfig().apiUrl;

    const downloadFile = (url: any, fileName: any, fileType: number = 3, updateProgress: any, completed: any) => 
    {      
      const fileUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download?fileName=${encodeURIComponent(url)}&fileType=${fileType}&access_token=${auth.getTokenForImages()}`;
      //const fileUrl = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/download?fileName=F2bis.zip&fileType=${fileType}&access_token=${auth.getTokenForImages()}`;
      
      fetch(fileUrl, {
        method: 'GET',
        headers: new Headers({
          Authorization: "Bearer " + auth.getTokenForImages()
        })        
      })
      .then(
        fetchProgress({
          onProgress(progress: any) {
            updateProgress(progress.percentage);            
          },
          onError(err) {
            console.log(err);
          },
        })
      )      
      .then((response: any) => {
        return response.blob(); })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        completed();
        
      })
      .catch((response) => {
        console.log(response);
        debugger;});

        return fileUrl;
    };

    //const [nextTemplateId, setNextTemplateId] = useState<string | null>(null);
    //const [canSaveTemplate, setCanSaveTemplate] = useState<boolean>(true);
    
    const { data: boreholeData, error: boreholeError, isLoading, isFetching } = useGetBoreholeSummaryQuery( {boreholeId, clientId, projectId }, { refetchOnMountOrArgChange: true });
    const [exportFile, { isLoading: isExportingFile }] = useExportFileMutation();    
    //const { data: trackTypesData, isLoading: trackTypesDataIsLoading } = useTrackTypesQuery({ boreholeId, clientId, projectId, trackCategoryId: selectedTrackCategoryId });
    const { data: trackTypesHierarchyData, isLoading: trackTypesHierarchyDataIsLoading } = useTrackTypesHierarchyQuery({ boreholeId, clientId, projectId }, { refetchOnMountOrArgChange: true });
    //const { data: trackCategoriesData, isLoading: trackCategoriesDataIsLoading } = useTrackCategoriesQuery({ boreholeId, clientId, projectId });
    const { data: imageTypes, isLoading: imageTypesDataIsLoading } = useImageTypesQuery({ boreholeId, clientId, projectId, imageViewer: false }, { refetchOnMountOrArgChange: true });
    const { data: paperSizes, isLoading: paperSizesIsLoading } = usePaperSizesQuery();
        
    //const { data: templates, error: templateError, isLoading: templateLoading, isFetching: templateFetching } = useTemplatesQuery();
//    const [add, { isLoading: isSaving }] = useAddMutation();
    const [createPdf, { isLoading: isSaving }] = useTrackMutation();

    const ItemTypes = {
      TRACK: 'track'
    }

    const pixelHeight = window.innerHeight;
    //const minDepth = data?.depthTypes[depthType].minDepth;
    //const maxDepth = data?.depthTypes[depthType].maxDepth;


    const dispatch: AppDispatch = useDispatch();
    const selectTemplates = (state: RootState) => state.templates;
    const { 
            allTemplates,            
            templatesLoading, 
            trackDataLoading,
            template, 
            trackData,
            groupTrackData,
            trackImages,
            groupTrackImages,
            notSaved,
            selectedTemplateId, 
            selectedBuiltInTemplateId, 
            selectedUserTemplateId,
            selectedBoreholeTemplateId,
            selectedProjectTemplateId,
            selectedClientTemplateId,
            canSaveTemplate, 
            canDeleteTemplate,
            templateLoading,
            nextTemplateId,
            nextTemplateLevel,
            error,
            minDepth,
            maxDepth,
            deletedCurves,
            viewer,
            dataNotSaved,
            urlTemplateId,
            statisticalTracks
          } = useSelector(selectTemplates);

          const selectViews = (state: RootState) => state.views;
          const { 
            view, 
            boreholes,
            showView,
            notSaved: viewNotSaved,
            viewsLoading,
            viewsLoaded,
            error: viewErrors,
            allViews,
            userViews,
            clientViews,
            selectedViewId,
            selectedUserViewId,
            selectedClientViewId,
            nextViewId,
            nextViewLevel
          } = useSelector(selectViews);

      const internalDepthUnit = template?.depthUnit;
      const depthUnit = template?.depthUnit;

      const [sortedTrackData, setSortedTrackData] = useState<any>();
      const [sortedGroupTrackData, setSortedGroupTrackData] = useState<any>();

      useEffect(() => {
        if (urlTemplateId) {             
          const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer/${urlTemplateId}`;
          dispatch(clearUrlTemplateId());
          history.push(url);
        }
      },[urlTemplateId]);

      useEffect(() => {
        if (selectedBuiltInTemplateId) {
          const selectedTemplateIdValue: any = builtInTemplateOptions.find((o:any) => o.value == selectedBuiltInTemplateId);
          setSelectedBuiltInTemplateIdValue(selectedTemplateIdValue);
          setSelectedUserTemplateIdValue(null);
          setSelectedBoreholeTemplateIdValue(null); 
          setSelectedProjectTemplateIdValue(null); 
          setSelectedClientTemplateIdValue(null);
        } else {
          setSelectedBuiltInTemplateIdValue(null);
        }
      },[selectedBuiltInTemplateId]);

      useEffect(() => {        
        if (selectedUserTemplateId) {                    
          const selectedTemplateIdValue: any = userTemplateOptions.find((o:any) => o.value == selectedUserTemplateId);
          setSelectedBuiltInTemplateIdValue(null);
          setSelectedUserTemplateIdValue(selectedTemplateIdValue);
          setSelectedBoreholeTemplateIdValue(null); 
          setSelectedProjectTemplateIdValue(null); 
          setSelectedClientTemplateIdValue(null);
          setSaveAsTemplateLevel(1);
          setCurrentTemplateLevel("User");
        } else {          
          setSelectedUserTemplateIdValue(null);
        }

      },[selectedUserTemplateId]);

      useEffect(() => {
        if (selectedBoreholeTemplateId) {
          const selectedTemplateIdValue: any = boreholeTemplateOptions.find((o:any) => o.value == selectedBoreholeTemplateId);
          setSelectedBuiltInTemplateIdValue(null);
          setSelectedUserTemplateIdValue(null);
          setSelectedBoreholeTemplateIdValue(selectedTemplateIdValue); 
          setSelectedProjectTemplateIdValue(null); 
          setSelectedClientTemplateIdValue(null);
          setSaveAsTemplateLevel(2);
          setCurrentTemplateLevel("Borehole");
        }
      },[selectedBoreholeTemplateId]);

      useEffect(() => {
        if (selectedProjectTemplateId) {
          const selectedTemplateIdValue: any = projectTemplateOptions.find((o:any) => o.value == selectedProjectTemplateId);
          setSelectedBuiltInTemplateIdValue(null);
          setSelectedUserTemplateIdValue(null);
          setSelectedBoreholeTemplateIdValue(null); 
          setSelectedProjectTemplateIdValue(selectedTemplateIdValue); 
          setSelectedClientTemplateIdValue(null);
          setSaveAsTemplateLevel(3);
          setCurrentTemplateLevel("Project");
        }
      },[selectedProjectTemplateId]);

      useEffect(() => {
        if (selectedClientTemplateId) {
          const selectedTemplateIdValue: any = clientTemplateOptions.find((o:any) => o.value == selectedClientTemplateId);
          setSelectedBuiltInTemplateIdValue(null);
          setSelectedUserTemplateIdValue(null);
          setSelectedBoreholeTemplateIdValue(null); 
          setSelectedProjectTemplateIdValue(null); 
          setSelectedClientTemplateIdValue(selectedTemplateIdValue);
          setSaveAsTemplateLevel(4);
          setCurrentTemplateLevel("Client");
        }
      },[selectedClientTemplateId]);

      useEffect(() => {
        if (selectedUserViewId) {
          const selectedViewIdValue: any = userViewOptions.find((o:any) => o.value == selectedUserViewId);
          setSelectedUserViewIdValue(selectedViewIdValue);          
          setSelectedClientViewIdValue(null);
          setSaveAsViewLevel(2);
          setCurrentViewLevel("User");
        }
      },[selectedUserViewId]);

      useEffect(() => {
        if (selectedClientViewId) {
          const selectedViewIdValue: any = clientViewOptions.find((o:any) => o.value == selectedClientViewId);          
          setSelectedUserViewIdValue(null);
          setSelectedClientViewIdValue(selectedViewIdValue);
          setSaveAsViewLevel(4);
          setCurrentViewLevel("Client");
        }
      },[selectedClientViewId]);

      useEffect(() => {
        if (selectedViewId && allViews?.length) {    
          const viewIdNum = +selectedViewId;
          const selectedViewIdValue: any = allViews.find((o:any) => o.id == viewIdNum);          
          if (selectedViewIdValue.templateLevel) {
            setSaveAsTemplateLevel(selectedViewIdValue.templateLevel);
          }
          let currentLevel: string | null = null;
          
          setSelectedUserViewIdValue(null);          
          setSelectedClientViewIdValue(null);
          switch (selectedViewIdValue.templateLevel)
          {            
            case 1:
              currentLevel = "User";
              setSelectedUserViewIdValue(viewIdNum);
              break;            
            case 4:
              currentLevel = "Client";
              setSelectedClientViewIdValue(viewIdNum);
              break;
          }

          setCurrentViewLevel(currentLevel);
//          dispatch(setSelectedViewId(viewIdNum));
        }
      },[selectedViewId, allViews]);

      //const clientTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 4).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});

      useEffect(() => {
        if (templateId && allTemplates?.length) {          
          const templateIdNum = +templateId;
          const selectedTemplateIdValue: any = allTemplates.find((o:any) => o.id == templateIdNum);
          // setSelectedBuiltInTemplateIdValue(null);
          // setSelectedUserTemplateIdValue(null);
          // setSelectedBoreholeTemplateIdValue(null); 
          // setSelectedProjectTemplateIdValue(null); 
          // setSelectedClientTemplateIdValue(selectedTemplateIdValue)          
          if (selectedTemplateIdValue.templateLevel) {
            setSaveAsTemplateLevel(selectedTemplateIdValue.templateLevel);
          }
          let currentLevel: string | null = null;
          setSelectedBuiltInTemplateIdValue(null);
          setSelectedUserTemplateIdValue(null);
          setSelectedBoreholeTemplateIdValue(null); 
          setSelectedProjectTemplateIdValue(null); 
          setSelectedClientTemplateIdValue(null);
          switch (selectedTemplateIdValue.templateLevel)
          {
            case 0:
              currentLevel = null;
              setSelectedBuiltInTemplateIdValue(templateIdNum);
              break;
            case 1:
              currentLevel = "User";
              setSelectedUserTemplateIdValue(templateIdNum);
              break;
            case 2:
              currentLevel = "Borehole";
              setSelectedBoreholeTemplateIdValue(templateIdNum);
              break;
            case 3:
              currentLevel = "Project";
              setSelectedProjectTemplateIdValue(templateIdNum);
              break;
            case 4:
              currentLevel = "Client";
              setSelectedClientTemplateIdValue(templateIdNum);
              break;
          }

          setCurrentTemplateLevel(currentLevel);
          dispatch(setSelectedTemplateId(templateIdNum));          
          setTrackLegendImage(null);
        }
      },[templateId, allTemplates]);

      useEffect(() => {
        if (viewId && allViews?.length) {          
          
          const viewIdNum = +viewId;
          const selectedViewIdValue: any = allViews.find((o:any) => o.id == viewIdNum);          
          if (selectedViewIdValue.templateLevel) {
            setSaveAsViewLevel(selectedViewIdValue.templateLevel);
          }
          let currentLevel: string | null = null;
          
          setSelectedUserViewIdValue(null);          
          setSelectedClientViewIdValue(null);
          switch (selectedViewIdValue.templateLevel)
          {            
            case 1:
              currentLevel = "User";
              setSelectedUserViewIdValue(viewIdNum);
              break;
           
            case 4:
              currentLevel = "Client";
              setSelectedClientViewIdValue(viewIdNum);
              break;
          }

          setCurrentViewLevel(currentLevel);
          dispatch(setSelectedViewId(viewIdNum));
          setTrackLegendImage(null);
        }
      },[viewId, allViews]);

const createSortedTrackData = (template: any) => {
  return template?.tracks?.map((t: any) => { 
    if (t.trackType === 0) { //graph
      if (!t.curves?.length) {
        return { 
          id: t.id,
          data: []
        }
      }
      //const curve = t.curves[0];            
      //var trackTrackData = trackData?.find((d: any) => curve.trackTypeId == d.trackTypeId);

      const curveTrackTypeIds = t.curves.map((curve: any) => curve.trackTypeId);

      var trackTrackDatas = trackData?.filter((d: any) => curveTrackTypeIds.includes(d.trackTypeId))

      const curves = t.curves.map((curve: any) => {
        const curveTrackData = trackTrackDatas.find((trackData: any) => trackData.trackTypeId === curve.trackTypeId && trackData.boreholeId == boreholeId);
        return  { ...curve, 
                  boreholeId,
                  noData: curveTrackData?.noData ?? true,
                  isAverage: curveTrackData?.isAverage ?? false,
                  scale: curveTrackData?.scale ?? null };
      });

      return { 
        id: t.id,
        boreholeId,
        displayOrder: t.displayOrder,
        trackWidth: t.trackWidth,
        curves: curves,
        label: curves.map((curve: any) => curve.displayName).join(", "), //t.curves[0]?.displayName,
        noData: trackTrackDatas.every((d: any) => d.noData ),
        trackType: t.trackType,          
        scale: trackTrackDatas[0]?.scale,
        isAverage: trackTrackDatas[0]?.isAverage,
        datas: trackTrackDatas,          
        showGridlines: t.showGridlines,
        showValueAxisAnnotation: t.showValueAxisAnnotation,
        scaleType: t.scaleType,
        collapsed: t.collapsed,
        isSelected: selectedTrack?.id === t.id,
        scrollIntoView: t.scrollIntoView,
        scrollAdjustmentIntoView: t.scrollAdjustmentIntoView
      }
    } else if (t.trackType === 8) { // horizontal bar graph
      if (!t.curves?.length) {
        return { 
          id: t.id,
          data: []
        }
      }
      //const curve = t.curves[0];            
      //var trackTrackData = trackData?.find((d: any) => curve.trackTypeId == d.trackTypeId);

      const curveTrackTypeIds = t.curves.map((curve: any) => curve.trackTypeId);

      var trackTrackDatas = trackData?.filter((d: any) => curveTrackTypeIds.includes(d.trackTypeId))

      const curves = t.curves.map((curve: any) => {
        const curveTrackData = trackTrackDatas.find((trackData: any) => trackData.trackTypeId === curve.trackTypeId && trackData.boreholeId == boreholeId);
        return  { ...curve, 
                  boreholeId,
                  noData: curveTrackData?.noData ?? true,
                  isAverage: curveTrackData?.isAverage ?? false,
                  scale: curveTrackData?.scale ?? null };
      });

      return { 
        id: t.id,
        boreholeId,
        displayOrder: t.displayOrder,
        trackWidth: t.trackWidth,
        curves: curves,
        label: curves.map((curve: any) => curve.displayName).join(", "), //t.curves[0]?.displayName,
        noData: trackTrackDatas.every((d: any) => d.noData ),
        trackType: t.trackType,          
        scale: trackTrackDatas[0]?.scale,
        isAverage: trackTrackDatas[0]?.isAverage,
        datas: trackTrackDatas,          
        showGridlines: t.showGridlines,
        showValueAxisAnnotation: t.showValueAxisAnnotation,
        scaleType: t.scaleType,
        collapsed: t.collapsed,
        isSelected: selectedTrack?.id === t.id,
        scrollIntoView: t.scrollIntoView,
        scrollAdjustmentIntoView: t.scrollAdjustmentIntoView
      }
    } else if (t.trackType === 1) { //Image              
      const trackImage = trackImages.find((i:any) => i.imageType == t.imageType);
      return { 
        id: t.id,
        boreholeId,
        displayOrder: t.displayOrder,
        trackType: t.trackType,
        imageType: t.imageType,
        displayName: t.displayName,
        label: t.displayName,
        legend: t.legend,
        legendRollover: t.legendRollover,
        trackWidth: t.trackWidth,
        collapsed: t.collapsed,
        isSelected: selectedTrack?.id === t.id,
        scrollIntoView: t.scrollIntoView,
        scrollAdjustmentIntoView: t.scrollAdjustmentIntoView,
        noImage: trackImage?.noImage,
        needsRegenerating: trackImage?.needsRegenerating
      }
    }
    else if (t.trackType === 3 || t.trackType === 4) { // Cumulative, Tadpole
      if (!t.curves?.length) {
        return { 
          id: t.id,
          data: []
        }
      }

      //const curve = t.curves[0];            
      //var trackTrackData = curve ? trackData?.find((d: any) => curve.trackTypeId == d.trackTypeId) : null;

      const curveTrackTypeIds = t.curves.map((curve: any) => curve.trackTypeId);
      var trackTrackDatas = trackData?.filter((d: any) => curveTrackTypeIds.includes(d.trackTypeId))
      
      const curves = t.curves.map((curve: any) => {
        const curveTrackData = trackTrackDatas.find((trackData: any) => trackData.trackTypeId === curve.trackTypeId);                
        return  { ...curve, 
          noData: curveTrackData?.noData ?? true,
          isAverage: curveTrackData?.isAverage ?? false,
          scale: curveTrackData?.scale ?? null };
      });

      return { 
        id: t.id,
        boreholeId,
        displayOrder: t.displayOrder,
        trackWidth: t.trackWidth,
        curves: curves,
        label:  curves.map((curve: any) => curve.displayName).join(", "),
        noData: trackTrackDatas.every((d: any) => d.noData),
        trackType: t.trackType,          
        scale: trackTrackDatas[0]?.scale,
        isAverage: trackTrackDatas[0]?.isAverage,
        datas: trackTrackDatas,
        showGridlines: t.showGridlines,
        showValueAxisAnnotation: t.showValueAxisAnnotation,
        scaleType: t.scaleType,
        collapsed: t.collapsed,
        isSelected: selectedTrack?.id === t.id,
        scrollIntoView: t.scrollIntoView,
        scrollAdjustmentIntoView: t.scrollAdjustmentIntoView
      }
    } 
    else if (t.trackType === 5) { // Lithology    
      
      var trackTrackDatas = trackData?.filter((d: any) => d.trackTypeId == t.lithologyType);
      if (!trackTrackDatas) {
        trackTrackDatas = [{ trackTypeId: t.lithologyType }];
      }

      const curves = t.curves.map((curve: any) => {
        const curveTrackData = trackTrackDatas.find((trackData: any) => trackData.trackTypeId == curve.trackTypeId);                
        return  { ...curve, 
          noData: curveTrackData?.noData ?? true,
          isAverage: curveTrackData?.isAverage ?? false,
          scale: curveTrackData?.scale ?? null };
      });

      const codesAndColors = trackTrackDatas?.length > 0 ? [...new Map(trackTrackDatas[0].data?.map((item: any) =>
        [item["value"], { code: item.value, color: item.color }])).values()].filter((value: any) => value.code) 
        : [];
      const trackEditMode = t.trackEditMode == undefined ? true : t.trackEditMode;
      
      return { 
        id: t.id,
        boreholeId,
        displayOrder: t.displayOrder,
        trackWidth: t.trackWidth,
        curves: curves,
        displayName: t.displayName,
        label: t.displayName,
        noData: trackTrackDatas.every((d: any) => d.noData),
        trackType: t.trackType,          
        scale: trackTrackDatas[0]?.scale,
        isAverage: trackTrackDatas[0]?.isAverage,
        lithologyTypeId: t.lithologyType,
        datas: trackTrackDatas,
        showGridlines: t.showGridlines,
        showValueAxisAnnotation: t.showValueAxisAnnotation,
        scaleType: t.scaleType,
        collapsed: t.collapsed,
        isSelected: selectedTrack?.id === t.id,
        scrollIntoView: t.scrollIntoView,
        scrollAdjustmentIntoView: t.scrollAdjustmentIntoView,
        codesAndColors,
        trackEditMode
      }
    } 
    else if (t.trackType === 7) { // text
      var trackTrackDatas = trackData?.filter((d: any) => d.trackTypeId == t.textTrackTypeId);
      if (!trackTrackDatas) {
        trackTrackDatas = [];
      }

      return { 
        id: t.id,
        boreholeId,
        displayOrder: t.displayOrder,
        datas: trackTrackDatas,
        textTrackTypeId: t.textTrackTypeId,
        trackWidth: t.trackWidth,              
        displayName: t.displayName,
        label: t.displayName,
        trackType: t.trackType,                                          
        collapsed: t.collapsed,
        isSelected: selectedTrack?.id === t.id,
        scrollIntoView: t.scrollIntoView,
        scrollAdjustmentIntoView: t.scrollAdjustmentIntoView                
      }
    } 
  })
};

      useEffect(() => {        
        if (template?.tracks && trackData && !showView) {
          setSortedTrackData(createSortedTrackData(template));
        }
      }, [template?.tracks, trackData]);

      const [templateLoaded, setTemplateLoaded] = useState<boolean>(false);

      useEffect(() => {
        if (template?.tracks?.length && showView && !templateLoaded) {                   
          // this is when templates is loaded after selecting multiple boreholes
          // add all the tracks to all the boreholes
          const localSortedTrackData = createSortedTrackData(template);
          dispatch(addTracksToGroups({ tracks: localSortedTrackData }));
        }        
      }, [template, showView]);

      useEffect(() => {
        if (template?.tracks?.length) {          
          setTemplateLoaded(true);
        }        
      }, [template?.tracks?.length]);      

      useEffect(() => {
        if (showView && view?.groups && (groupTrackData?.length || groupTrackImages?.length)) {               
          setSortedGroupTrackData(view.groups.map((group: any) => {
            const tracks = group.tracks?.map((t: any) => {
              if (t.trackType === 0) { //graph
                if (!t.curves?.length) {
                  return { 
                    id: t.id,
                    data: []
                  }
                }
                //const curve = t.curves[0];            
                //var trackTrackData = trackData?.find((d: any) => curve.trackTypeId == d.trackTypeId);
        
                const curveBoreholeTrackTypeIds = t.curves.map((curve: any) => { return { trackTypeId: curve.trackTypeId, boreholeId: curve.boreholeId }});
                
                let trackTrackDatas: any[] = [];
                groupTrackData.forEach((data: any) => {
                  curveBoreholeTrackTypeIds.forEach((track: any) => {                    
                    if (data.boreholeId == track.boreholeId && data.trackTypeId == track.trackTypeId) {
                      trackTrackDatas.push(data);
                    }
                  });
                });

                const curves = t.curves.map((curve: any) => {        
                  const curveTrackData = trackTrackDatas.find((trackData: any) => trackData.trackTypeId === curve.trackTypeId && trackData.boreholeId === curve.boreholeId);
                  return  { ...curve, 
                            color: curve.color || t.color,
                            boreholeId,
                            noData: curveTrackData?.noData ?? true,
                            isAverage: curveTrackData?.isAverage ?? false,
                            scale: curveTrackData?.scale ?? null };
                });
      
                return { 
                  id: t.id,
                  displayOrder: t.displayOrder,
                  trackWidth: t.trackWidth,
                  color: t.color,
                  curves: curves,
                  label: curves.map((curve: any) => curve.displayName).join(", "), //t.curves[0]?.displayName,
                  boreholeName: t.boreholeName,
                  projectName: t.projectName,
                  noData: trackTrackDatas.every((d: any) => d.noData ),
                  trackType: t.trackType,          
                  scale: trackTrackDatas[0]?.scale,
                  isAverage: trackTrackDatas[0]?.isAverage,
                  datas: trackTrackDatas,          
                  showGridlines: t.showGridlines,
                  showValueAxisAnnotation: t.showValueAxisAnnotation,
                  scaleType: t.scaleType,
                  collapsed: t.collapsed,
                  isSelected: selectedTrack?.id === t.id,
                  scrollIntoView: t.scrollIntoView,
                  scrollAdjustmentIntoView: t.scrollAdjustmentIntoView
                }
              } else if (t.trackType === 1) { //Image                    
                const trackImage = groupTrackImages.find((i:any) => i.imageType == t.imageType && i.boreholeId === t.boreholeId);                
                return { 
                  id: t.id,
                  boreholeId: t.boreholeId,
                  boreholeName: t.boreholeName,
                  projectName: t.projectName,
                  color: t.color,
                  displayOrder: t.displayOrder,
                  trackType: t.trackType,
                  imageType: t.imageType,
                  displayName: t.displayName,
                  label: t.displayName,
                  legend: t.legend,
                  legendRollover: t.legendRollover,
                  trackWidth: t.trackWidth ?? 150,
                  collapsed: t.collapsed,
                  isSelected: selectedTrack?.id === t.id,
                  scrollIntoView: t.scrollIntoView,
                  scrollAdjustmentIntoView: t.scrollAdjustmentIntoView,
                  noImage: trackImage?.noImage ?? true,
                  needsRegenerating: trackImage?.needsRegenerating ?? false
                }
              }
              else if (t.trackType === 3 || t.trackType === 4) { // Cumulative, Tadpole
                
                if (!t.curves?.length) {
                  return { 
                    id: t.id,
                    data: []
                  }
                }
        
                //const curve = t.curves[0];            
                //var trackTrackData = curve ? trackData?.find((d: any) => curve.trackTypeId == d.trackTypeId) : null;
        
                const curveBoreholeTrackTypeIds = t.curves.map((curve: any) => { return { trackTypeId: curve.trackTypeId, boreholeId: curve.boreholeId }});                
                let trackTrackDatas: any[] = [];
                groupTrackData.forEach((data: any) => {
                  curveBoreholeTrackTypeIds.forEach((track: any) => {
                    if (data.boreholeId == track.boreholeId && data.trackTypeId == track.trackTypeId) {
                      trackTrackDatas.push(data);
                    }
                  });
                });

                const curves = t.curves.map((curve: any) => {
                  const curveTrackData = trackTrackDatas.find((trackData: any) => trackData.trackTypeId === curve.trackTypeId && trackData.boreholeId == curve.boreholeId);
                  return  { ...curve, 
                    noData: curveTrackData?.noData ?? true,
                    isAverage: curveTrackData?.isAverage ?? false,
                    scale: curveTrackData?.scale ?? null };
                });
        
                return { 
                  id: t.id,
                  displayOrder: t.displayOrder,
                  trackWidth: t.trackWidth,
                  curves: curves,
                  label:  curves.map((curve: any) => curve.displayName).join(", "),
                  boreholeName: t.boreholeName,
                  projectName: t.projectName,
                  noData: trackTrackDatas.every((d: any) => d.noData),
                  trackType: t.trackType,          
                  scale: trackTrackDatas[0]?.scale,
                  isAverage: trackTrackDatas[0]?.isAverage,
                  datas: trackTrackDatas,
                  showGridlines: t.showGridlines,
                  showValueAxisAnnotation: t.showValueAxisAnnotation,
                  scaleType: t.scaleType,
                  collapsed: t.collapsed,
                  isSelected: selectedTrack?.id === t.id,
                  scrollIntoView: t.scrollIntoView,
                  scrollAdjustmentIntoView: t.scrollAdjustmentIntoView
                }
              } 
              else if (t.trackType === 5) { // Lithology                
                var trackTrackDatas = groupTrackData?.filter((d: any) => (d.trackTypeId == t.trackTypeId || d.trackTypeId == t.lithologyTypeId) && d.boreholeId == t.boreholeId);
                if (!trackTrackDatas) {
                  trackTrackDatas = [{ trackTypeId: t.trackTypeId }];
                }

                const codesAndColors = trackTrackDatas?.length > 0 ? [...new Map(trackTrackDatas[0].data?.map((item: any) =>
                  [item["value"], { code: item.value, color: item.color }])).values()].filter((value: any) => value.code) 
                  : [];
                const trackEditMode = t.trackEditMode == undefined ? true : t.trackEditMode;
                
                return { 
                  id: t.id,
                  displayOrder: t.displayOrder,
                  trackWidth: t.trackWidth,
                  color: t.color,
                  curves: [],
                  boreholeId: t.boreholeId,
                  displayName: t.displayName,
                  label: t.displayName,
                  boreholeName: t.boreholeName,
                  projectName: t.projectName,
                  noData: trackTrackDatas.every((d: any) => d.noData),
                  trackType: t.trackType,          
                  scale: trackTrackDatas[0]?.scale,
                  isAverage: trackTrackDatas[0]?.isAverage,
                  lithologyTypeId: t.lithologyTypeId || t.trackTypeId,
                  datas: trackTrackDatas,
                  showGridlines: t.showGridlines,
                  showValueAxisAnnotation: t.showValueAxisAnnotation,
                  scaleType: t.scaleType,
                  collapsed: t.collapsed,
                  isSelected: selectedTrack?.id === t.id,
                  scrollIntoView: t.scrollIntoView,
                  scrollAdjustmentIntoView: t.scrollAdjustmentIntoView,
                  codesAndColors,
                  trackEditMode
                }
              } 
              else if (t.trackType === 7) { // text                                
                var trackTrackDatas = groupTrackData?.filter((d: any) => (d.trackTypeId == t.trackTypeId || d.trackTypeId == t.textTrackTypeId) && d.boreholeId == t.boreholeId);
                if (!trackTrackDatas) {
                  trackTrackDatas = [];
                }

                return { 
                  id: t.id,
                  color: t.color,
                  displayOrder: t.displayOrder,
                  boreholeId: t.boreholeId,
                  datas: trackTrackDatas,
                  textTrackTypeId: t.textTrackTypeId,
                  trackWidth: t.trackWidth,              
                  displayName: t.displayName,
                  label: t.displayName,
                  boreholeName: t.boreholeName,
                  projectName: t.projectName,
                  trackType: t.trackType,                                          
                  collapsed: t.collapsed,
                  isSelected: selectedTrack?.id === t.id,
                  scrollIntoView: t.scrollIntoView,
                  scrollAdjustmentIntoView: t.scrollAdjustmentIntoView                
                }
              } 
            });

            return { groupName: group.groupName, groupId: group.groupId, color: group.color, boreholeId: group.boreholeId, collapsed: group.collapsed, tracks };
          }));

          if (minDepth) {
            setStartDepth(minDepth);
          }
          if (maxDepth) {
            setEndDepth(maxDepth);
          }
        }
      }, [view?.groups, groupTrackData, groupTrackImages, showView]);

    const url = window.location.pathname.split('/').pop();

    useEffect(() => {
      dispatch(clearTemplatesLoaded());
      dispatch(getTemplates({ templateType: 0, clientId, projectId, boreholeId }));
      dispatch(getViews({ templateType: 0 }));
    }, [url]);

    useEffect(() => {
      if (boreholeData?.name) {
        setBorehole({name: boreholeData?.name, id: boreholeId});        
      }
    },[boreholeData?.name])


    useEffect(() => {
      if (boreholeData?.name && template?.name) {        
        setExportFileName(boreholeData.name + " - " + template?.name);
      }
    },[boreholeData?.name, template?.name])


    useEffect(() => {
      if (!templateLoading && template?.depthUnit) {
        setDepthUnit(template?.depthUnit);
        setSaveTemplateName(template?.name);
      }   
    },[templateLoading]);

    // useEffect(() => {
    //   if (selectedTemplateId) {
    //     debugger;
    //     GetTrackData();        
    //   }   
    // },[clientId, 
    //   projectId,
    //   boreholeId,      
    //   pixelHeight,      
    //   startDepth, 
    //   endDepth, 
    //   byBoreholeDepth]);

    useEffect(() => {      
      if (boreholeData && depthType) {        
        setStartDepth(boreholeData.depthTypes[depthType].minDepth);
        setEndDepth(boreholeData.depthTypes[depthType].maxDepth);
      }
    },[boreholeData, depthType]);

     useEffect(() => {      
        if (selectedTemplateId && viewer === "Borehole") {
          if (showView) {            
            const boreholeIds = boreholes.map((borehole: any) => borehole.id);                  
            dispatch(getMultipleBoreholeTrackDataForTemplate({ 
              clientId,
              boreholeIds,
              templateId:selectedTemplateId,
              pixelHeight,
              startDepth,
              endDepth,
              byBoreholeDepth,
              depthUnit: internalDepthUnit }));
          } else {
            dispatch(getTrackData({ 
              clientId, 
              projectId,
              boreholeId,
              pixelHeight, 
              templateId: selectedTemplateId, 
              byBoreholeDepth,
              startDepth,
              endDepth,
              depthUnit }));          
          }
        }   
      },[selectedTemplateId,
        pixelHeight,      
        startDepth, 
        endDepth, 
        depthUnit,
        byBoreholeDepth]);

    useEffect(() => {      
      if (selectedViewId && viewer === "Borehole") {               
          dispatch(getMultipleBoreholeTrackDataForView({ 
            clientId,        
            viewId: selectedViewId,
            pixelHeight,
            startDepth,
            endDepth,
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));    
      }   
    },[selectedViewId,
      pixelHeight,      
      startDepth, 
      endDepth, 
      depthUnit,
      byBoreholeDepth]);
            
      useEffect(() => {
        if (depthUnitChanged) {
          setDepthUnit(internalDepthUnit);
          GetTrackData();
          setDepthUnitChanged(false);
        }   
      },[depthUnitChanged]);
      
      const GetTrackData = () => {
        const existingTrackTypesIds = trackData.map((data: any) => data.trackTypeId);
        dispatch(getTrackDataForTrackTypes({ 
          clientId, 
          projectId,
          boreholeId,
          pixelHeight, 
          trackTypeIds: existingTrackTypesIds, 
          startDepth, 
          endDepth, 
          byBoreholeDepth,
          depthUnit: internalDepthUnit }));
      };
      
     useEffect(() => {
        if (selectedTemplateId && viewer == "Borehole") {          
          dispatch(getTemplate(selectedTemplateId));             
        } else {
          setSelectedBuiltInTemplateIdValue(null);
          setSelectedUserTemplateIdValue(null);
          setSelectedBoreholeTemplateIdValue(null);
          setSelectedProjectTemplateIdValue(null);
          setSelectedClientTemplateIdValue(null);
        }
      },[selectedTemplateId]);

      useEffect(() => {        
        if (selectedViewId && viewer == "Borehole") {          
          dispatch(getView(selectedViewId));             
        } else {          
          setSelectedUserViewIdValue(null);          
          setSelectedClientViewIdValue(null);
        }
      },[selectedViewId]);
    
      /*
    useEffect(() => {
      setMinDepth(data?.depthTypes[depthType].minDepth);
      setMaxDepth(data?.depthTypes[depthType].maxDepth);
    },[data?.depthTypes, depthType]);
    */

    // useEffect(() => {        
    //    // if (maxDepth !== null && minDepth !== null) {        
    //         setWasLoading(false);
    //         //setInternalStartDepth(minDepth);
    //         //setInternalEndDepth(maxDepth); 
    //         if (minDepth != null) {
    //           setDepthTrackStartDepth(minDepth);
    //         }

    //         if (maxDepth != null) {
    //           setDepthTrackEndDepth(maxDepth);
    //         }

    //         setSkipLoad(false);
    //  //   }
    //   }, [maxDepth, minDepth]);

      // useEffect(() => {              
      //     if (startDepth) {
      //        setDepthTrackStartDepth(startDepth);
      //     }
      //    }, [startDepth]);

      // useEffect(() => {        
      //   if (endDepth) {
      //     setDepthTrackEndDepth(endDepth);
      //   }
      // }, [endDepth]);

    // useEffect(() => {
    //   if (templatesLoaded && allTemplates && allTemplates.length > 0) {
    //     const firstTemplateWithData = allTemplates.find((t: any) => t.containsData);
    //     if (firstTemplateWithData) {          
    //       switch (firstTemplateWithData.templateLevel)
    //       {
    //         case 0:
    //           selectBuiltInTemplate(firstTemplateWithData.id);
    //           break;
    //         case 1:
    //           selectUserTemplate(firstTemplateWithData.id);
    //           break;
    //         case 2:
    //           selectBoreholeTemplate(firstTemplateWithData.id);
    //           break;
    //         case 3:
    //           selectProjectTemplate(firstTemplateWithData.id);
    //           break;
    //         case 4:
    //           selectClientTemplate(firstTemplateWithData.id);
    //           break;
    //       }
          
    //     } else {
    //       //const templateId = allTemplates[0].id;
    //       //selectBuiltInTemplate(templateId);              
    //     }        
    //   }
    // },[templatesLoaded, url]);  

    const onChangeBuiltInTemplate = (e: any) => {           
      if (notSaved || dataNotSaved.some((x: any) => x)) {         
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 0}));
        setShowNotSavedModal(true);
      } else {            
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer/${e.value}`;
        history.push(url);
        //selectBuiltInTemplate(e.value);
      }
    };

    const onChangeUserTemplate = (e: any) => {     
      if (notSaved || dataNotSaved.some((x: any) => x)) {
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 1}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer/${e.value}`;
        history.push(url);
        //selectUserTemplate(e.value);
      }
    };

    const onChangeBoreholeTemplate = (e: any) => {     
      if (notSaved || dataNotSaved.some((x: any) => x)) {
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 2}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer/${e.value}`;
        history.push(url);
        //selectBoreholeTemplate(e.value);
      }
    };

    const onChangeProjectTemplate = (e: any) => {     
      if (notSaved || dataNotSaved.some((x: any) => x)) {
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 3}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer/${e.value}`;
        history.push(url);
        //selectProjectTemplate(e.value);
      }
    };

    const onChangeClientTemplate = (e: any) => {     
      if (notSaved || dataNotSaved.some((x: any) => x)) {
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 4}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer/${e.value}`;
        history.push(url);        
      }
    };

    const onChangeUserView = (e: any) => {     
      dispatch(clearTemplate());
      if (notSaved || dataNotSaved.some((x: any) => x)) {
        dispatch(setNextViewId({nextViewId: e.value, nextViewLevel: 1}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer/view/${e.value}`;
        history.push(url);
        //selectUserView(e.value);        
        //setViewId(e.value);
      }
    };

    const onChangeClientView = (e: any) => {
      dispatch(clearTemplate());
      if (notSaved || dataNotSaved.some((x: any) => x)) {
        dispatch(setNextViewId({nextViewId: e.value, nextViewLevel: 1}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/log-viewer/view/${e.value}`;
        history.push(url);
        //selectClientView(e.value);        
        //setViewId(e.value);
      }
    };

    const selectBuiltInTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = builtInTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(selectedTemplateIdValue2);
      setSelectedUserTemplateIdValue(null);
      setSelectedBoreholeTemplateIdValue(null);
      setSelectedProjectTemplateIdValue(null);
      setSelectedClientTemplateIdValue(null);
      dispatch(setSelectedBuiltInTemplateId(templateId)); 
      setCurrentTemplateLevel("Default");
      setSaveAsTemplateLevel(1);
    }

    const selectUserTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = userTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(null);      
      setSelectedUserTemplateIdValue(selectedTemplateIdValue2);
      setSelectedBoreholeTemplateIdValue(null);
      setSelectedProjectTemplateIdValue(null);
      setSelectedClientTemplateIdValue(null);
      dispatch(setSelectedUserTemplateId(templateId)); 
      setCurrentTemplateLevel("User");
      setSaveAsTemplateLevel(1);
    }
   

    const selectBoreholeTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = boreholeTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(null);
      setSelectedUserTemplateIdValue(null);
      setSelectedBoreholeTemplateIdValue(selectedTemplateIdValue2);
      setSelectedProjectTemplateIdValue(null);
      setSelectedClientTemplateIdValue(null);
      dispatch(setSelectedBoreholeTemplateId(templateId)); 
      setCurrentTemplateLevel("Borehole");
      setSaveAsTemplateLevel(2);
    }

    const selectProjectTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = projectTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(null);
      setSelectedUserTemplateIdValue(null);
      setSelectedBoreholeTemplateIdValue(null);
      setSelectedProjectTemplateIdValue(selectedTemplateIdValue2);
      setSelectedClientTemplateIdValue(null);
      dispatch(setSelectedProjectTemplateId(templateId)); 
      setCurrentTemplateLevel("Project");
      setSaveAsTemplateLevel(3);
    }

    const selectClientTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = clientTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(null);
      setSelectedUserTemplateIdValue(null);
      setSelectedBoreholeTemplateIdValue(null);
      setSelectedProjectTemplateIdValue(null);
      setSelectedClientTemplateIdValue(selectedTemplateIdValue2);
      dispatch(setSelectedClientTemplateId(templateId)); 
      setCurrentTemplateLevel("Client");
      setSaveAsTemplateLevel(4);
    }

    const selectUserView = (viewId: string) => {      
      const selectedViewIdValue2: any = userViewOptions.find((o:any) => o.value == viewId);      
      setSelectedUserViewIdValue(selectedViewIdValue2);      
      setSelectedClientViewIdValue(null);
      dispatch(setSelectedUserViewId(viewId)); 
      setCurrentViewLevel("User");
      setSaveAsViewLevel(1);
    }

    const selectClientView = (viewId: string) => {
      const selectedViewIdValue2: any = userViewOptions.find((o:any) => o.value == viewId);      
      setSelectedUserViewIdValue(null);
      setSelectedClientViewIdValue(selectedViewIdValue2);
      dispatch(setSelectedClientViewId(viewId)); 
      setCurrentViewLevel("Client");
      setSaveAsViewLevel(1);
    }

    const onChangeDepthType = (e: any) => {      
      setDepthType(e.target.value);      
      setByBoreholeDepth(e.target.value == "Borehole");      
    }

    const onChangeDepthUnit = (value: number) => {
      dispatch(setDepthUnit(value));
      setDepthUnitChanged(true);
    }

    const onSaveTemplate = () => {
      dispatch(updateTemplate({...template, id: selectedTemplateId, name: saveTemplateName }));
      setEditMode(false);
    };

    const onSaveView = () => {      
      dispatch(updateView({...view, viewId: selectedViewId, name: saveViewName }));
      setEditMode(false);
    };

    const onEdit = () => {
      setEditMode(!editMode);
    };

    const onSaveTemplateAs = () => {      
      let copyNameTemplate = '';      
      if (template?.name.endsWith("Copy")){
        copyNameTemplate = template?.name + " 1";
      } else {
        if (template?.name) {
          copyNameTemplate = template?.name + " Copy";
        }          
      }      
      let copyName = copyNameTemplate;      
      let copyCount = 1;
      while (allTemplates.find((t: any) => t.name.toLowerCase() === copyName.toLowerCase().trim())) {
        copyName = copyNameTemplate + ` ${copyCount}`;
        copyCount++;
      }

      setSaveTemplateName(copyName);
      setSaveTemplateNameError(null);
      setShowSaveAsModal(true);
      setEditMode(false);
    };

    const onSaveViewAs = () => {      
      let copyNameTemplate = '';      
      if (view?.name?.endsWith("Copy")){
        copyNameTemplate = view?.name + " 1";
      } else {
        if (view?.name) {
          copyNameTemplate = view?.name + " Copy";
        }          
      }      
      let copyName = copyNameTemplate;      
      let copyCount = 1;
      while (allViews.find((t: any) => t.name.toLowerCase() === copyName.toLowerCase().trim())) {
        copyName = copyNameTemplate + ` ${copyCount}`;
        copyCount++;
      }

      setSaveViewName(copyName);
      setSaveViewNameError(null);
      
      setShowSaveViewAsModal(true);      
    };

    const onNewTemplate = () => {
      if (notSaved || dataNotSaved.some((x: any) => x)) {
        dispatch(setNextTemplateId({nextTemplateId: null}));
        setShowNotSavedModal(true);
      } else {
        setSaveTemplateName("");
        setSaveAsTemplateLevel(1);      
        setSaveTemplateNameError(null);
        // setShowSaveAsModal(true);
        setEditMode(false);
        dispatch(addNewTemplate());
      }
    };

    const onDeleteTemplate = () => {
      confirmAlert({
        title: 'Confirm Delete Template',
        message: `Are you sure you want to delete template ${template?.name}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(deleteTemplate({templateId: selectedTemplateId, templateLevel: saveAsTemplateLevel}));               
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };

    const onSaveAsFormSubmit = () => {
      handleOkSaveAsModal();
    };

    const onSaveViewAsFormSubmit = () => {
      handleOkSaveViewAsModal();
    };

    const saveTemplateAs = () => {             
      const changeToNew = nextTemplateId == null;
      dispatch(addTemplate({ ...template, name: saveTemplateName, changeToNew, templateType: 0, templateLevel: +saveAsTemplateLevel, clientId, projectId, boreholeId }));
    };

    const saveViewAs = () => {             
      const changeToNew = nextViewId == null;
      dispatch(addView({ ...view, name: saveViewName, changeToNew, templateType: 0, templateLevel: +saveAsViewLevel, clientId, projectId, boreholeId }));
    };

    const handleOkSaveAsModal = () => {      
      if (allTemplates.find((t: any) => t.templateLevel == saveAsTemplateLevel && t.name.toLowerCase() === saveTemplateName.toLowerCase().trim())) {        
        setSaveTemplateNameError(["Cannot have duplicate names in the same level."]);
      } else {        
        saveTemplateAs();
        setShowSaveAsModal(false);        
      }
    };

    const handleOkSaveViewAsModal = () => {      
      if (allViews.find((t: any) => t.viewLevel == saveAsViewLevel && t.name.toLowerCase() === saveViewName.toLowerCase().trim())) {        
        setSaveTemplateNameError(["Cannot have duplicate names in the same level."]);
      } else {        
        saveViewAs();
        setShowSaveViewAsModal(false);        
      }
    };

    const handleCloseSaveAsModal = () => {
      setShowSaveAsModal(false);
    };

    const handleCloseSaveViewAsModal = () => {
      setShowSaveViewAsModal(false);
    };

    const handleCloseNotSavedModal = () => {
      setShowNotSavedModal(false);
    };

    const handleYesNotSavedModal = () => {
      setShowNotSavedModal(false);      
      let gotoNextTemplate = false;      
      if (notSaved) {
        if (canSaveTemplate) {
          onSaveTemplate();
          gotoNextTemplate = true;          
        } else {
          onSaveTemplateAs();
        }             
      }

        if (dataNotSaved.some((x: any) => x)) {        
          dataNotSaved.forEach((trackTypeId: any) => onSaveLithologyData(trackTypeId, boreholeId));
          gotoNextTemplate = true;
        }

        if (gotoNextTemplate) {
          if (nextTemplateId) {
            switch (nextTemplateLevel) {
              case 0: 
                selectBuiltInTemplate(nextTemplateId);
                break;
              case 1: 
                selectUserTemplate(nextTemplateId);
                break;
              case 2: 
                selectBoreholeTemplate(nextTemplateId);
                break;
              case 3: 
                selectProjectTemplate(nextTemplateId);
                break;
              case 4: 
                selectClientTemplate(nextTemplateId);
                break;
            }

            dispatch(setNextTemplateId(null));
          } else {
            setSaveTemplateName("");
            setSaveAsTemplateLevel(1);      
            setSaveTemplateNameError(null);
            setEditMode(false);
            dispatch(addNewTemplate());
          } 
        }
    };

    const handleNoNotSavedModal = () => {
      setShowNotSavedModal(false);
      if (nextTemplateId) {
        switch (nextTemplateLevel) {
          case 0: 
            selectBuiltInTemplate(nextTemplateId);
            break;
          case 1: 
            selectUserTemplate(nextTemplateId);
            break;
          case 2: 
            selectBoreholeTemplate(nextTemplateId);
            break;
          case 3: 
            selectProjectTemplate(nextTemplateId);
            break;
          case 4: 
            selectClientTemplate(nextTemplateId);
            break;
        }
        
        dispatch(setNextTemplateId(null));
        dispatch(clearNotSaved());
      } else {
        setSaveTemplateName("");
        setSaveAsTemplateLevel(1);      
        setSaveTemplateNameError(null);
        setEditMode(false);
        dispatch(addNewTemplate());
      }
    };

    const onAddStatisticalTrack = (trackTypes: any[]) => {
      dispatch(addStatisticsTracks({ trackTypes }));
    }

    const onDeleteStatisticalTrack = (trackType: any) => {

      confirmAlert({
        title: 'Confirm Delete Statistic Track',
        message: `Are you sure you want to delete ${trackType.displayName}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(deleteStatisticsTrack({ trackTypeId: trackType.trackTypeId }));
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }

    const handleOkAddTrackModal = () => {   
      
      const allTrackTypes = trackTypesHierarchyData?.trackTypeCategories.reduce((previousValue : any, currentValue: any) => {
        if (currentValue.trackTypes) {
          previousValue.push(...currentValue.trackTypes);
        }
        return previousValue;
      }, []);
      const selectedBoreholeIds = [boreholeId];
      if (selectedTemplateTrackType === 0) { // graph        
        const selectedTrackTypes = checkedTrackTypes.map((trackTypeId: any) => {
          const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);
          return { trackTypeId, displayName: trackType?.name, allowedCurveTypes: trackType?.allowedCurveTypes, units: trackType?.units }
        });
        if (showView) {
          const boreholeTrackTypes: any[] = [];

          selectedTrackTypes.forEach((trackType: any) => {
              boreholes.forEach((borehole: any) => {
                boreholeTrackTypes.push({trackTypeId: trackType.trackTypeId, boreholeId: borehole.id });
              });              
          });

          dispatch(addGraphTracksToGroups({ tracks: selectedTrackTypes }));
          dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
            clientId,
            boreholeTrackTypes,
            boreholeImageTypes: [],
            pixelHeight,
            startDepth,
            endDepth,
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        } else {
          dispatch(addGraphTracks({ trackTypes: selectedTrackTypes, boreholeIds: selectedBoreholeIds }));
          dispatch(getTrackDataForTrackTypes({ 
            clientId, 
            projectId,
            boreholeId,
            pixelHeight, 
            trackTypeIds: checkedTrackTypes, 
            startDepth, 
            endDepth, 
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        }
      } else if (selectedTemplateTrackType === 1) { //image
        const selectedTrackTypes = checkedImageTypes.map((imageTypeValue: any) => {          
          const imageType = imageTypes.imageTypes.find((imageType: any) => imageType.value == imageTypeValue);
          return { imageType: imageTypeValue, displayName: imageType?.name, legendName: imageType?.legendName, legendRollover: imageType?.legendRollover, noImages: imageType?.noImages }
        });

        if (showView) {
          const boreholeImageTypes: any[] = [];
          selectedTrackTypes.forEach((trackType: any) => {
              boreholes.forEach((borehole: any) => {
                boreholeImageTypes.push({imageTypeId: trackType.imageType, boreholeId: borehole.id });
              });              
          });

          dispatch(addImageTracksToGroups({ tracks: selectedTrackTypes }));
          dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
            clientId,
            boreholeTrackTypes: [],
            boreholeImageTypes,
            pixelHeight,
            startDepth,
            endDepth,
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        } else {
          dispatch(addImageTracks({imageTrackTypes: selectedTrackTypes, boreholeIds: selectedBoreholeIds}));
        }
      } else if (selectedTemplateTrackType === 3) { // cumulative
        const selectedTrackTypes = checkedTrackTypes.map((trackTypeId: any) => {
          const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);
          return { trackTypeId, displayName: trackType?.name }
        });


        if (showView) {
          const boreholeTrackTypes: any[] = [];

          selectedTrackTypes.forEach((trackType: any) => {
              boreholes.forEach((borehole: any) => {
                boreholeTrackTypes.push({trackTypeId: trackType.trackTypeId, boreholeId: borehole.id });
              });              
          });

          dispatch(addCumulativeTracksToGroups({ tracks: selectedTrackTypes }));
          dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
            clientId,
            boreholeTrackTypes,
            boreholeImageTypes: [],
            pixelHeight,
            startDepth,
            endDepth,
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        } else {
          dispatch(addCumulativeTrack({ trackTypes: selectedTrackTypes, boreholeIds: selectedBoreholeIds }));
          dispatch(getTrackDataForTrackTypes({ 
            clientId, 
            projectId,
            boreholeId,
            pixelHeight, 
            trackTypeIds: checkedTrackTypes, 
            startDepth, 
            endDepth, 
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        }
      } else if (selectedTemplateTrackType === 4) { // tadpole
        const selectedTrackTypes = checkedTrackTypes.map((trackTypeId: any) => {
          const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);
          return { trackTypeId, displayName: trackType?.name }
        });
        if (showView) {
          const boreholeTrackTypes: any[] = [];

          selectedTrackTypes.forEach((trackType: any) => {
              boreholes.forEach((borehole: any) => {
                boreholeTrackTypes.push({ trackTypeId: trackType.trackTypeId, boreholeId: borehole.id });
              });              
          });

          dispatch(addTadpoleTracksToGroups({ tracks: selectedTrackTypes }));
          dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
            clientId,
            boreholeTrackTypes,
            boreholeImageTypes: [],
            pixelHeight,
            startDepth,
            endDepth,
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));          
        } else {
          dispatch(addTadpoleTrack({ trackTypes: selectedTrackTypes, boreholeIds: selectedBoreholeIds }));
          dispatch(getTrackDataForTrackTypes({ 
            clientId, 
            projectId,
            boreholeId,
            pixelHeight, 
            trackTypeIds: checkedTrackTypes, 
            startDepth, 
            endDepth, 
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        }
      } else if (selectedTemplateTrackType === 5) { // lithology
        const selectedLithologyTypes = checkedLithologyTypes.map((trackTypeId: any) => {
          const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);          
          return { trackTypeId: trackTypeId.toString(), displayName: trackType?.name }
        });
        if (showView) {
          const boreholeTrackTypes: any[] = [];

          selectedLithologyTypes.forEach((trackType: any) => {
              boreholes.forEach((borehole: any) => {
                boreholeTrackTypes.push({ trackTypeId: trackType.trackTypeId, boreholeId: borehole.id });
              });              
          });

          dispatch(addLithologyTracksToGroups({ tracks: selectedLithologyTypes }));
          dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
            clientId,
            boreholeTrackTypes,
            boreholeImageTypes: [],
            pixelHeight,
            startDepth,
            endDepth,
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));    
          
        } else {
          dispatch(addLithologyTrack({ trackTypes: selectedLithologyTypes, boreholeIds: selectedBoreholeIds }));
          
          dispatch(getTrackDataForTrackTypes({ 
            clientId, 
            projectId,
            boreholeId,
            pixelHeight, 
            trackTypeIds: checkedLithologyTypes, 
            startDepth, 
            endDepth, 
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        }
      } else if (selectedTemplateTrackType === 7) { // Text     
        const trackTypeId = checkedTextTypes[0];
        if (showView) {
          const boreholeTrackTypes: any[] = [];

          boreholes.forEach((borehole: any) => {
            boreholeTrackTypes.push({ trackTypeId: trackTypeId, boreholeId: borehole.id });
          });                        

          dispatch(addDescriptionTracksToGroups({ trackTypeId }));
          dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
            clientId,
            boreholeTrackTypes,
            boreholeImageTypes: [],
            pixelHeight,
            startDepth,
            endDepth,
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));    
          
        } else {   
          
          dispatch(addTextTrack({ trackTypeId, boreholeIds: selectedBoreholeIds }));        
          dispatch(getTrackDataForTrackTypes({ 
            clientId, 
            projectId,
            boreholeId,
            pixelHeight, 
            trackTypeIds: [trackTypeId], 
            startDepth, 
            endDepth, 
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        }
      }  else if (selectedTemplateTrackType === 8) { // horizontal bar graph        
        const selectedTrackTypes = checkedTrackTypes.map((trackTypeId: any) => {
          const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);
          return { trackTypeId, displayName: trackType?.name, allowedCurveTypes: trackType?.allowedCurveTypes, units: trackType?.units }
        });
        if (showView) {
          const boreholeTrackTypes: any[] = [];

          selectedTrackTypes.forEach((trackType: any) => {
              boreholes.forEach((borehole: any) => {
                boreholeTrackTypes.push({trackTypeId: trackType.trackTypeId, boreholeId: borehole.id });
              });              
          });

          dispatch(addGraphTracksToGroups({ tracks: selectedTrackTypes }));
          dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
            clientId,
            boreholeTrackTypes,
            boreholeImageTypes: [],
            pixelHeight,
            startDepth,
            endDepth,
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        } else {
          dispatch(addHorizontalBarGraphTracks({ trackTypes: selectedTrackTypes, boreholeIds: selectedBoreholeIds }));
          dispatch(getTrackDataForTrackTypes({ 
            clientId, 
            projectId,
            boreholeId,
            pixelHeight, 
            trackTypeIds: checkedTrackTypes, 
            startDepth, 
            endDepth, 
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        }
      }

      setTemplateLoaded(true);
      setCheckedTrackTypes([]);
      setCheckedImageTypes([]);
      setCheckedLithologyTypes([]);
    };

    const onAddTrackFormSubmit = () => {
      handleOkAddTrackModal();
    };

    const onAddBoreholeFormSubmit = (boreholeIds: any[]) => {
      // need to add all existing tracks for all borehole Ids. Don't include any that already exist
      
      
    };

    const onUpdateCurve = (templateTrackId: string, curve: any) => {
      dispatch(updateCurve({ templateTrackId, curve}));
    }

    const onUpdateCurves = (templateId: any, templateTrackId: string, curves: any, groupId: any) => {
      const newCurves = curves.filter((curve: any) => curve.new);
      dispatch(updateCurves({ templateId, templateTrackId, curves }));
      // if any new ones, get new data
      if (newCurves && newCurves.length > 0) {
        const newTrackTypeIds = newCurves.map((curve: any) => curve.trackTypeId);
        const existingTrackTypesIds = trackData.map((data: any) => data.trackTypeId)
        const nonExistingTrackTypeIds = newTrackTypeIds.filter((trackTypeId: any) => !existingTrackTypesIds.includes(trackTypeId));
        if (newTrackTypeIds.length > 0) {
          dispatch(getTrackDataForTrackTypes({ 
            clientId, 
            projectId,
            boreholeId,
            pixelHeight, 
            trackTypeIds: nonExistingTrackTypeIds, 
            startDepth, 
            endDepth, 
            byBoreholeDepth,
            depthUnit: internalDepthUnit }));
        }
      }
    }
    
    const onUpdateCurves2 = (templateTrackId: string, curves: any, groupId: any) => {
      const newCurves = curves.filter((curve: any) => curve.new);
      if (showView && groupId) {
        dispatch(updateViewCurves({ groupId, trackId: templateTrackId, curves }));

        //meed to get the data
        const boreholeTrackTypes: any[] = [];

        curves.forEach((curve: any) => {            
          boreholeTrackTypes.push({ trackTypeId: curve.trackTypeId, boreholeId: curve.boreholeId });            
        });

        dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
          clientId,                     
          pixelHeight, 
          boreholeTrackTypes: boreholeTrackTypes, 
          boreholeImageTypes: [],
          startDepth, 
          endDepth, 
          byBoreholeDepth,
          depthUnit: internalDepthUnit }));
      } else {
        dispatch(updateCurves({ selectedTemplateId, templateTrackId, curves }));
        // if any new ones, get new data
        if (newCurves && newCurves.length > 0) {
          const newTrackTypeIds = newCurves.map((curve: any) => curve.trackTypeId);
          const existingTrackTypesIds = trackData.map((data: any) => data.trackTypeId)
          const nonExistingTrackTypeIds = newTrackTypeIds.filter((trackTypeId: any) => !existingTrackTypesIds.includes(trackTypeId));
          if (newTrackTypeIds.length > 0) {            
            dispatch(getTrackDataForTrackTypes({ 
              clientId, 
              projectId,
              boreholeId,
              pixelHeight, 
              trackTypeIds: nonExistingTrackTypeIds, 
              startDepth, 
              endDepth, 
              byBoreholeDepth,
              depthUnit: internalDepthUnit }));
          }
        }
      }
    }

    const onAddCurve = (trackId: any) => {
      dispatch(addCurve({trackId}));
    };

    const onSetShowGridlines = (templateTrackId: string, show: boolean) => {
      dispatch(setShowGridlines({selectedTemplateId, templateTrackId, show}));
    };
    
    const onSetScaleType = (templateTrackId: string, scaleType: number) => {
      dispatch(setScaleType({selectedTemplateId, templateTrackId, scaleType}));
    };

    const onSetTrackWidth = (templateId: any, templateTrackId: string, width: number) => {      
      dispatch(setTrackWidth({templateId, templateTrackId, width}));      
    };

    const onSetViewTrackWidth = (groupId: any, trackId: string, width: number) => {
        dispatch(setViewTrackWidth({groupId, trackId, width}));
    };

    const onRemoveTrack = (trackTypeId: any, trackId: any, groupId: any) => {
      if (showView) {
        dispatch(deleteTrack({trackId, groupId}));
      }
      dispatch(removeTrack({trackTypeId, groupId}));
    };
    
    const onSetDepthMajorIntervals = (intervals: number) => {
      if (showView) {
        dispatch(setViewDepthMajorIntervals(intervals))
      } else {
        dispatch(setDepthMajorIntervals(intervals))
      }      
    };

    const onSetDepthMinorIntervals = (intervals: number) => {
      if (showView) {
        dispatch(setViewDepthMinorIntervals(intervals))
      } else {
        dispatch(setDepthMinorIntervals(intervals))
      }
    };

    const onSetShowDepthMinorIntervals = (show: boolean) => {
      if (showView) {
        dispatch(setViewShowDepthMinorIntervals(show));
      } else {
        dispatch(setShowDepthMinorIntervals(show));
      }
    };

    const onSetShowValueAxisAnnotation = (show: boolean) => {
      if (showView) {
        dispatch(setViewShowValueAxisAnnotation(show));
      } else {
        dispatch(setShowValueAxisAnnotation(show));
      }
    };

    const onSetShowDepthGridlines = (show: boolean) => {
      if (showView) {
        dispatch(setViewShowDepthGridlines(show));
      } else {
        dispatch(setShowDepthGridlines(show));
      }
    };

    const onCreatePdf = () => {
      setShowPrintModal(true);      
    };

    const handleClosePrintModal = () => {
      setShowPrintModal(false);
    }

    const handleOkPrintModal = (size: any, 
                                orientation: any, 
                                pagesToPrintOver: number, 
                                pixelWidth: number, 
                                pixelHeight: number, 
                                fanFold: boolean, 
                                tracksPerPage: number, 
                                fixTracksPerPage: boolean,
                                totalPages : number | null,
                                fixTotalPages: boolean,
                                printStartDepth: number,
                                printEndDepth: number,
                                depthPerPage: number | null,
                                printOverPages: boolean,
                                leftDepthScale: boolean,
                                rightDepthScale: boolean, 
                                printStatisticalSummary: boolean,
                                selectedDataForDepthRange: any) => {      
      setIsPrinting(true);
      setCreatePdfError(null);

      var language;      
      if (window.navigator.languages) {
          language = window.navigator.languages[0];
      } else {
          language = window.navigator.language;
      }
            
      createPdf({ template: { 
              connectionId: connection.connectionId,
              clientId, 
              projectId, 
              boreholeId, 
              templateId: showView ? null : selectedTemplateId, 
              viewId: showView ?selectedViewId : null,
              startDepth: printStartDepth, 
              endDepth: printEndDepth, 
              size, 
              orientation, 
              byBoreholeDepth, 
              pagesToPrintOver, 
              pixelWidth, 
              pixelHeight,
              fanFold,
              tracksPerPage,
              fixTracksPerPage,
              totalPages,
              fixTotalPages,
              depthPerPage,
              printOverPages,
              leftDepthScale,
              rightDepthScale,
              printStatisticalSummary,
              selectedDataForDepthRange,
              culture: language
             }})
             .unwrap()
             .then((payload: any) => console.log('fulfilled', payload))
             .catch((error: any) => { 
                setIsPrinting(false);
                setCreatePdfError(error?.data?.errors);
              });
        setCreatePdfProgress({progress: null, message: "Pending", failed: false});
    };

    const onExportFile = (headerType: any, deliminator: any) => {      
      setIsExporting(true);
      setExportCompleted(false);
      setExportProgress({progress: null, message: "Pending", failed: false })
      exportFile({ clientId, projectId, boreholeId, templateId: selectedTemplateId, exportFileName, headerType, deliminator, orderByBoreholeDepth: depthType === "Borehole", connectionId: connection.connectionId  });
    };
            


    // userTemplates.sort((a: any, b: any) => {
    //   return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    // })
    
    // const templateOptions:any =  [
    //   {
    //     label: "Fixed",
    //     options: defaultTemplates.map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}})
    //   },
    //   { label: "User",
    //   options: userTemplates.map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}})
    // }
    // ];

    const builtInTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 0).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});
    const userTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 1).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});
    const boreholeTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 2).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});
    const projectTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 3).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});
    const clientTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 4).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});

    const userViewOptions: any = allViews.filter((t:any) => t.templateLevel === 1).map((t: any) => { return { label: t.name, value: t.id }});
    const clientViewOptions: any = allViews.filter((t:any) => t.templateLevel === 4).map((t: any) => { return { label: t.name, value: t.id }});
    
    const customStyles = {
      option: (provided: any, { data, isDisabled, isFocused, isSelected }:any) => ({
        ...provided,          
        backgroundColor: isSelected ? 'rgba(0, 54, 113, 1)' : '#FFFFFF',
        padding: 5,
        paddingLeft: "12px",
        "&:hover": {
          backgroundColor: "rgba(0, 54, 113, 0.3)"
        }
      })        
    };

    const onShowAdjustments = () => {
      if (!showRightSidePanel) {
        setShowRightSidePanel(true);
      }

      setShowAddStatisticsPanel(false);
      setShowAddTrackPanel(false);
      setShowAdjustmentsPanel(true);
      setShowReorderPanel(false);
      setShowAddBoreholePanel(false);
    };

    const onShowAddStatistics = () => {
      if (!showRightSidePanel) {
        setShowRightSidePanel(true);
      }

      setShowAddStatisticsPanel(true);
      setShowAddTrackPanel(false);
      setShowAdjustmentsPanel(false);
      setShowReorderPanel(false);
      setShowAddBoreholePanel(false);
    };

    const onSetShowReorderPanel = () => {
      if (!showRightSidePanel) {
        setShowRightSidePanel(true);
      }

      setShowAddStatisticsPanel(false);
      setShowReorderPanel(true);
      setShowAddTrackPanel(false);
      setShowAdjustmentsPanel(false);
      setShowAddBoreholePanel(false);
    };

    const onShowHideAdjustmentsPanel = () => {
      if (showRightSidePanel) {
        onCloseRightSidePanel()
      } else {
        onShowAddTrackPanel()         
      }
    }

    const onShowAddTrackPanel = () => {
      if (!showRightSidePanel) {
        setShowRightSidePanel(true);
      }

      setShowAddStatisticsPanel(false);
      setShowAddTrackPanel(true);
      setShowAdjustmentsPanel(false);
      setShowReorderPanel(false);
      setShowAddBoreholePanel(false);
    };

    const onShowAddBoreholePanel = () => {
      if (!showRightSidePanel) {
        setShowRightSidePanel(true);
      }

      setShowAddStatisticsPanel(false);
      setShowAddTrackPanel(false);
      setShowAdjustmentsPanel(false);
      setShowReorderPanel(false);
      setShowAddBoreholePanel(true);
    };

    const onHideAddTrackPanel = () => {
      setShowAddTrackPanel(false);
      setShowRightSidePanel(false);
    };

    const onCloseRightSidePanel = () => {
      setShowAddTrackPanel(false);
      setShowAdjustmentsPanel(false);
      setShowReorderPanel(false);
      setShowRightSidePanel(false);
      setShowAddBoreholePanel(false);
      setSelectedTrack(null);
    };

    const onUpdateTrackOrder = (sourceIndex: number, destinationIndex: number) => {
      dispatch(updateTrackOrder({sourceIndex, destinationIndex}));
    };

    const onUpdateGroupTrackOrder = (sourceGroupId: any, destinationGroupId: any, sourceIndex: number, destinationIndex: number, applyToAll: boolean) => {
      dispatch(updateGroupTrackOrder({sourceGroupId, destinationGroupId, sourceIndex, destinationIndex, applyToAll}));
    };    

    const onUpdateGroupOrder = (sourceIndex: number, destinationIndex: number) => {
      dispatch(updateGroupOrder({ sourceIndex, destinationIndex }));
    };    

    const onSetSelectedTrack = (track: any, groupId: any) => {   
      setSelectedTrack(track);
      if (!track) {
        return;
      }

      onSetTrackLegendImage(track);

      dispatch(scrollTrackIntoView({ trackId: track.id }));
      if (track.canAdjustImage) {
        if (!showRightSidePanel) {
          setShowRightSidePanel(true);
        }
       
        if (!showAdjustmentsPanel) {
          onShowAdjustments();
        }          
      }        
    };

    const onSetTrackLegendImage = (track: any) => {
      if (track.legend) {
        const src = `${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/legend?imageType=${track.imageType}&access_token=${auth.getTokenForImages()}`;
        setTrackLegendImage(src);
      } else {
        setTrackLegendImage(null);
      }
    };

    const onSetTrackCollapsed = (trackId: string, collapsed: boolean, groupId: any) => {
      if (groupId) {
        dispatch(setGroupTrackCollapsed({ trackId, collapsed, groupId }));
      } else {
        dispatch(setTrackCollapsed({ trackId, collapsed }));
      }      
    }

    const deselectAllTracks = () => {
      setSelectedTrack(null); 
      onCloseRightSidePanel();
    };

    const onTrackHeaderClick = (track: any, groupId: any) => {            
      if (track.id === selectedTrack?.id) {
      //  setSelectedTrack(null); 
//        onCloseRightSidePanel();
      } else {
        setSelectedTrack(track);
        setSelectedGroupId(groupId);
        onSetTrackLegendImage(track);

        dispatch(scrollAdjustmentIntoView({ trackId: track.id }));
        if (!track.noData || track.trackType === 5) {
          if (!showRightSidePanel) {
            setShowRightSidePanel(true);
          }

          if (!showAdjustmentsPanel) {
            onShowAdjustments();
          }          
        }
      }
    };

    const onSelectLithology = (track: any, lithologyLexiconId: string) => {      
      setSelectedLithologyId(lithologyLexiconId);
    };

    const onAddLithology = (trackTypeId: any, depth: number) =>{
      const id = uuidv4();      
      dispatch(addDataValue({ id, trackTypeId, depth, showView }));
      setSelectedLithologyId(id);
    };

    const onShowHideLeftSidePanel = () => {    
      if (showLeftSidePanel)  {
        setShowDepthPanel(false);
        setShowPrintPanel(false);
        setShowTemplatesPanel(false);
        setShowViewsPanel(false);
        setShowInfoPanel(false);
        setShowXAxisPanel(false);
        setShowExportPanel(false);
      } else {
        setShowDepthPanel(true);
      }

      setShowLeftSidePanel(!showLeftSidePanel);
    };

    const onShowDepthPanel = () => {      
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }

      setShowDepthPanel(true);
      setShowPrintPanel(false);
      setShowTemplatesPanel(false);
      setShowViewsPanel(false);
      setShowInfoPanel(false);
      setShowXAxisPanel(false);
      setShowExportPanel(false);
    };

    const onShowXAxisPanel = () => {      
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }

      setShowDepthPanel(false);
      setShowPrintPanel(false);
      setShowTemplatesPanel(false);
      setShowViewsPanel(false);
      setShowInfoPanel(false);
      setShowXAxisPanel(true);
      setShowExportPanel(false);
    };

    const onShowPrintPanel = () => {      
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }

      setShowDepthPanel(false);
      setShowPrintPanel(true);
      setShowTemplatesPanel(false);
      setShowViewsPanel(false);
      setShowInfoPanel(false);
      setShowXAxisPanel(false);
      setShowExportPanel(false);
    };

    const onShowTemplatesPanel = () => {      
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }

      setShowDepthPanel(false);
      setShowPrintPanel(false);
      setShowTemplatesPanel(true);
      setShowViewsPanel(false);
      setShowInfoPanel(false);
      setShowXAxisPanel(false);
      setShowExportPanel(false);
    };

    const onShowViewsPanel = () => {      
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }

      setShowDepthPanel(false);
      setShowPrintPanel(false);
      setShowTemplatesPanel(false);
      setShowViewsPanel(true);
      setShowInfoPanel(false);
      setShowXAxisPanel(false);
      setShowExportPanel(false);
    };

    const onShowInfoPanel = () => {      
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }

      setShowDepthPanel(false);
      setShowPrintPanel(false);
      setShowTemplatesPanel(false);
      setShowViewsPanel(false);
      setShowInfoPanel(true);
      setShowXAxisPanel(false);
      setShowExportPanel(false);
    };

    const onShowExportPanel = () => {      
      if (!showLeftSidePanel) {
        setShowLeftSidePanel(true);
      }

      setIsExporting(false);
      setExportCompleted(false);
      setShowDepthPanel(false);
      setShowPrintPanel(false);
      setShowTemplatesPanel(false);
      setShowViewsPanel(false);
      setShowInfoPanel(false);
      setShowXAxisPanel(false);
      setShowExportPanel(true);
    };

    const onSetMetaDataDepth = (depthAndValues: any) => {
      setMetaDataDepth(depthAndValues);
    };

    const moveTemplateLevel = (newLevel: any) => {
      if (allTemplates.find((t: any) => t.templateLevel == newLevel && t.name.toLowerCase() === saveTemplateName.toLowerCase().trim())) {
        setMoveTemplateError("Cannot have duplicate names in the same level.");
      } else {
        setMoveTemplateError(null);
        dispatch(changeTemplateLevel({id: selectedTemplateId, templateLevel: newLevel, clientId, projectId, boreholeId }));
      }
    };
    
    const moveViewLevel = (newLevel: any) => {
      if (allViews.find((t: any) => t.templateLevel == newLevel && t.name.toLowerCase() === saveTemplateName.toLowerCase().trim())) {
        setMoveViewError("Cannot have duplicate names in the same level.");
      } else {
        setMoveViewError(null);
        dispatch(changeViewLevel({id: selectedViewId, templateLevel: newLevel, boreholeId }));
      }
    };

    const onChangeDepth = (trackTypeId: any, id: string, newDepth: any) => {
      dispatch(changeDepth({trackTypeId, id, newDepth}));
    };


    const onAddDataValue = (trackTypeId: any, depth: any, value: any, color: string, lithologyLexiconId: string, boreholeId: number) => {
      dispatch(addDataValue({trackTypeId, depth, value, color, lithologyLexiconId, showView, boreholeId }));
    }

    const onChangeDataValue = (id: string, trackTypeId: any, value: any, color: string, lithologyLexiconId: string, boreholeId: number) => {
      dispatch(changeDataValue({id, trackTypeId, value, color, lithologyLexiconId, showView, boreholeId }));
    }

    const onDeleteDataValue = (trackTypeId: any, id: string, boreholeId: number) => {
      dispatch(deleteDataValue({ trackTypeId, id, showView, boreholeId }));
    };

    const onAddTextValue = (textTrackTypeId: any, fromDepth: number, toDepth: number, value: any, boreholeId: number) => {
      // check if valid            
      let track = showView ? groupTrackData.find((t: any) => t.trackTypeId === textTrackTypeId && t.boreholeId === boreholeId) : trackData.find((t: any) => t.trackTypeId === textTrackTypeId);      
      if (track) {
          const textTrackData = track.data;
          if (textTrackData) {
            // if new from is within an existing data
            const fromDepthError = textTrackData.findIndex((d: any) => fromDepth >= d.fromDepth && fromDepth < d.toDepth) !== -1;

            // if new to is within an existing data
            const toDepthError = textTrackData.findIndex((d: any) => toDepth > d.fromDepth && toDepth < d.toDepth) !== -1;

            if (fromDepthError || toDepthError) {
              return false;
            }
          }
      }

      dispatch(addTextValue({textTrackTypeId, fromDepth, toDepth, value, showView, boreholeId }));
      return true;
    }

    const onChangeTextData = (trackTypeId: any, data: any, boreholeId: number) => {
      dispatch(changeTextData({trackTypeId, data, showView, boreholeId }));
    }    

    const onSaveLithologyData = (trackTypeId: any, boreholeId: number) => {      
       //= trackData.find((t: any) => t.trackTypeId === trackTypeId).data;
       const trackData2 = showView ? groupTrackData.find((t: any) => t.trackTypeId === trackTypeId && t.boreholeId === boreholeId)?.data : trackData.find((t: any) => t.trackTypeId === trackTypeId)?.data;
      const data = { trackTypeId, data: trackData2 }
      dispatch(saveLithologyData({ clientId, projectId, boreholeId, data}));
    }

    const onSaveTextData = (trackTypeId: any, data: any, boreholeId: number) => {
      dispatch(saveTextData({ clientId, projectId, boreholeId, trackTypeId, data}));
    }

    const onSetTrackEditMode = (trackId: any, editMode: boolean) => {
      dispatch(setTrackEditMode({ trackId, editMode }));
    };

    const containsAssayData = trackTypesHierarchyData?.trackTypeCategories.filter((c: any) => c.id === 1035)
                                                                          .reduce( (accumulator: any, currentValue: any) => [...accumulator, ...currentValue.trackTypes.filter((t: any) => !t.noData)], [])                                                                                 
                                                                          .length > 0;

    const dataForDepthRangeOptions = trackTypesHierarchyData?.trackTypeCategories.filter((c: any) => c.id === 1033 || c.id === 1038)
                                                                                 .reduce( (accumulator: any, currentValue: any) => [...accumulator, ...currentValue.trackTypes.filter((t: any) => !t.noData)], [])                                                                                 
                                                                                 .map((t: any) => { return { value: t.id, name: t.name }});
    if (containsAssayData) {
      dataForDepthRangeOptions.push({ value: -99, name: "Assay" });
    }

    const mapTracksForView = (borehole: any, localSortedTrackData: any, boreholeTrackTypes: any[], boreholeImageTypes: any[]) => {
      return localSortedTrackData.map((track: any) => {                
        if (track.lithologyTypeId) {            
          boreholeTrackTypes.push({boreholeId: borehole.id, trackTypeId: track.lithologyTypeId });
        }
        
        if (track.textTrackTypeId) {
          boreholeTrackTypes.push({boreholeId: borehole.id, trackTypeId: track.textTrackTypeId });
        }
        
        if (track.trackType === 1) {          
          boreholeImageTypes.push({boreholeId: borehole.id, imageTypeId: track.imageType });              
        }

        return { 
          ...track,
          boreholeName: borehole.name, 
          projectName: borehole.project,
          boreholeId: borehole.id,
          trackName: track.label,
          trackId: track.id,
          trackType: track.trackType,          
          curves: track.curves?.map((curve: any) =>{ 
            boreholeTrackTypes.push({boreholeId: borehole.id, trackTypeId: curve.trackTypeId });
            return { 
            ...curve,  
            displayName: curve.displayName, 
            boreholeName: borehole.name, 
            boreholeId: borehole.id, 
            projectName: borehole.project,
            trackCategoryName: curve.trackCategoryName
          }})            
        }});    
    }

    const onAddBoreholes = (boreholes: any) => {
      
      const boreholeTrackTypes: any[] = [];
      const boreholeImageTypes: any[] = [];
      let minBoreholeDepth = startDepth || 99999;
      let maxBoreholeDepth = endDepth || 0;
      
      if (showView) {        
        if (sortedGroupTrackData) {          
          // find common tracks from all groups that have boreholeId
          const boreholeGroups = sortedGroupTrackData.filter((group: any) => group.boreholeId);

          const tracksToAdd: any[] = [];
          const group = boreholeGroups[0];
          
          group?.tracks.forEach((track: any) => {
            if (track.trackType === 1) { // Images
              let trackExistsInAll = true;
              boreholeGroups.forEach((innerGroup: any) => { 
                // for each group, check to see if the tracks contain this imageTypeId
                if (!innerGroup.tracks.filter((track: any) => track.trackType === 1).map((innerTrack: any) => innerTrack.imageTypeId).includes(track.imageTypeId)) {
                  trackExistsInAll = false;
                }                  
              });            

              if (trackExistsInAll) {
                tracksToAdd.push({...track, datas: [], noData: true });
              }
            } else {
              const trackTypesIds = track.curves?.map((curve: any) => curve.trackTypeId);
              let trackExistsInAll = false;
              if (trackTypesIds) {
                boreholeGroups.forEach((innerGroup: any) => { 
                  innerGroup.tracks.forEach((innerTrack: any) => {
                    const innerTrackTypeIds = innerTrack.curves?.map((curve: any) => curve.trackTypeId);
                    if (innerTrackTypeIds) {
                      const intersectedArray = trackTypesIds.filter((value: any) => innerTrackTypeIds.includes(value));
                      if (intersectedArray.length === innerTrackTypeIds.length) {
                        trackExistsInAll = true;
                      }
                    }
                  });
                });            

                if (trackExistsInAll) {
                  tracksToAdd.push({...track, datas: [], noData: true });
                }
              }
            }
          });

          const mappedBoreholes = Array.from(boreholes);      

          mappedBoreholes.forEach((borehole: any) => {  
            const mappedTracks = mapTracksForView(borehole, tracksToAdd, boreholeTrackTypes, boreholeImageTypes);             
            borehole.tracks = mappedTracks;
          })
          dispatch(addBoreholes({boreholes: mappedBoreholes}));          
        }
      } else {
        const mappedBoreholes = Array.from(boreholes);      
        mappedBoreholes.forEach((borehole: any) => {
          const mappedTracks = mapTracksForView(borehole, sortedTrackData, boreholeTrackTypes, boreholeImageTypes);    
          borehole.tracks = mappedTracks;
        })
        dispatch(addBoreholes({boreholes: mappedBoreholes, template}));
      }

      boreholes.forEach((borehole: any) => {
        if (borehole.minBoreholeDepth < minBoreholeDepth) {
          minBoreholeDepth = borehole.minBoreholeDepth;
        }

        if (borehole.maxBoreholeDepth > maxBoreholeDepth) {
          maxBoreholeDepth = borehole.maxBoreholeDepth;
        }
      });
      
      setStartDepth(minBoreholeDepth);
      setEndDepth(maxBoreholeDepth);

      dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
        clientId,
        boreholeTrackTypes,
        boreholeImageTypes,
        pixelHeight,
        startDepth: minBoreholeDepth,
        endDepth: maxBoreholeDepth,
        byBoreholeDepth,
        depthUnit: internalDepthUnit }));
    }

    const onCollapseGroup = (groupId: any) => {
      dispatch(setGroupCollapsed({groupId}));
    };

    return (
      <div>
          
          <div className="left-side-panel" style={{width: showLeftSidePanel ? 300 : 0 }}>
            {showDepthPanel && 
              <DepthPanel
                depthType={depthType}
                depthUnit={depthUnit}                
                childLoading={childLoading}
                onChangeDepthType={onChangeDepthType}
                changeDepthUnit={onChangeDepthUnit}                
                setStartDepth={setStartDepth}
                setEndDepth={setEndDepth}
                minDepth={boreholeData.depthTypes[depthType].minDepth}
                maxDepth={boreholeData.depthTypes[depthType].maxDepth}                
                setSkipLoad={setSkipLoad}                
                showDepthGridLines={template?.showDepthGridLines}
                majorIntervals={template?.depthMajorIntervals}
                setMajorIntervals={onSetDepthMajorIntervals}
                setShowDepthGridlines={onSetShowDepthGridlines}
                showDepthMinorIntervals={template?.showDepthMinorIntervals}
                setShowDepthMinorIntervals={onSetShowDepthMinorIntervals}
                minorIntervals={template?.depthMinorIntervals}
                setMinorIntervals={onSetDepthMinorIntervals}                
              /> }
            {showPrintPanel && <PrintPanel
              onCreatePdf={onCreatePdf}
              childLoading={childLoading}
              showPrintModal={showPrintModal}
                        handleClosePrintModal={handleClosePrintModal}
                        handleOkPrintModal={handleOkPrintModal}
                        isPrinting={isPrinting}
                        boreholeId={boreholeId}
                        projectId={projectId}
                        clientId={clientId}
                        headerImageFileName={boreholeData?.pdfHeaderImageFileName}
                        apiUrl={apiUrl}
                        authToken={auth.getToken()}
                        paperSizes={paperSizes}
                        startDepth={startDepth}
                        endDepth={endDepth}
                        minDepth={minDepth}
                        maxDepth={maxDepth}
                        depthUnit={depthUnit}
                        error={createPdfError}
                        progress={createPdfProgress}
                        showSummaryReport={boreholeData?.showSummaryReport && !showView}
                        statisticsTracks={template?.statisticsTracks}
                        dataForDepthRangeOptions={dataForDepthRangeOptions}
              /> }
            {showTemplatesPanel && !showView  && <TemplatesPanel 
              currentTemplate={template}
              currentTemplateLevel={currentTemplateLevel}
              moveTemplateLevel={moveTemplateLevel}
              moveError={moveTemplateError}
              builtInTemplateOptions={builtInTemplateOptions}
              userTemplateOptions={userTemplateOptions}
              boreholeTemplateOptions={boreholeTemplateOptions}
              projectTemplateOptions={projectTemplateOptions}
              clientTemplateOptions={clientTemplateOptions}
              onChangeBuiltInTemplate={onChangeBuiltInTemplate}
              onChangeUserTemplate={onChangeUserTemplate}
              onChangeBoreholeTemplate={onChangeBoreholeTemplate}
              onChangeProjectTemplate={onChangeProjectTemplate}
              onChangeClientTemplate={onChangeClientTemplate}
              selectedTemplateId={templateId}
              selectedBuiltInTemplateIdValue={selectedBuiltInTemplateIdValue}
              selectedUserTemplateIdValue={selectedUserTemplateIdValue}
              selectedBoreholeTemplateIdValue={selectedBoreholeTemplateIdValue}
              selectedProjectTemplateIdValue={selectedProjectTemplateIdValue}
              selectedClientTemplateIdValue={selectedClientTemplateIdValue}
              childLoading={childLoading}
              onSave={onSaveTemplate}
              canSaveTemplate={canSaveTemplate}
              canDeleteTemplate={canDeleteTemplate}
              notSaved={notSaved}
              onSaveAs={onSaveTemplateAs}
              deleteTemplate={onDeleteTemplate}
              onNewTemplate={onNewTemplate}
              /> }

            {showViewsPanel && showView && <ViewsPanel 
              currentView={view}
              currentViewLevel={currentViewLevel}              
              moveViewLevel={moveViewLevel}
              moveError={moveViewError}              
              viewsLoading={viewsLoading}
              userViewOptions={userViewOptions}
              clientViewOptions={clientViewOptions}              
              onChangeUserView={onChangeUserView}
              onChangeClientView={onChangeClientView}
              selectedViewId={selectedViewId}
              selectedUserViewIdValue={selectedUserViewIdValue}
              selectedClientViewIdValue={selectedClientViewIdValue}
              childLoading={childLoading}
              onSave={onSaveView}
              canSaveView={true}
              canDeleteView={canDeleteTemplate}
              notSaved={viewNotSaved}
              onSaveAs={onSaveViewAs}
              deleteView={onDeleteTemplate}
              onNewView={onNewTemplate}
              /> }
            {showInfoPanel && <InfoPanel 
              metaDataDepth={metaDataDepth}
              depthUnit={depthUnit}
              latitude={boreholeData.latitude}
              longitude={boreholeData.longitude}
              loggingStarted={boreholeData.loggingStarted}
              loggingEnded={boreholeData.loggingEnded}
              loggingTeam={boreholeData.loggingTeam}
              comments={boreholeData.comments}
              status={boreholeData.status}
              equipment={boreholeData.equipment}
              image={trackLegendImage}
              /> }     
            {showXAxisPanel && <XAxisPanel
                showValueAxisAnnotation={showView ? view?.showValueAxisAnnotation : template?.showValueAxisAnnotation}
                setShowValueAxisAnnotation={onSetShowValueAxisAnnotation}
             />}
             {showExportPanel && <ExportPanel 
                          exportFile={onExportFile} 
                          isExporting={isExporting} 
                          progress={exportProgress}
                          exportFileName={exportFileName} 
                          setExportFileName={setExportFileName}
                          downloaded={exportCompleted}
                          url={exportUrl} />}
          </div>
          <LeftSideMenu 
            showLeftSidePanel={showLeftSidePanel}
            onShowHideLeftSidePanel={onShowHideLeftSidePanel}
            showDepthPanel={showDepthPanel}
            onShowDepthPanel={onShowDepthPanel}
            showXAxisPanel={showXAxisPanel}
            onShowXAxisPanel={onShowXAxisPanel}
            showPrintPanel={showPrintPanel}
            onShowPrintPanel={onShowPrintPanel}
            showTemplatesPanel={showTemplatesPanel}
            onShowTemplatesPanel={onShowTemplatesPanel}
            showViewsPanel={showViewsPanel}
            onShowViewsPanel={onShowViewsPanel}
            notSaved={notSaved}
            showInfoPanel={showInfoPanel}
            onShowInfoPanel={onShowInfoPanel}
            showExportPanel={showExportPanel}
            onShowExportPanel={onShowExportPanel}
            templateSelected={template?.tracks.length || view?.groups.length}
            showView={showView}
            templateLoaded={templateLoaded}
            viewNotSaved={viewNotSaved}
          />
          
        <div style={{ marginLeft: showLeftSidePanel ? 350 : 50, marginRight: showRightSidePanel ? 477 : 50 }} className="sidebar-main">
          <div className="level log-viewer-title">            
              <div className="level-left">
                <div className="level-item">
                  <h2 className="mt-2" onClick={deselectAllTracks}>{showView ? "Correlation Viewer" : boreholeData?.name}</h2>
                </div>
                <div className="level-item">
                  <DepthData depthUnit={depthUnit} metaDataDepth={metaDataDepth}/>                
                </div>
              </div>            
          </div>
          <section>
            <div>          
                <div>
                <DndProvider backend={HTML5Backend}>
                  
                  {(templatesLoading || trackDataLoading) && <Loader />}
                  
                    <SaveAsDialog 
                      showSaveAsModal={showSaveAsModal}
                      handleCloseSaveAsModal={handleCloseSaveAsModal}
                      handleOkSaveAsModal={handleOkSaveAsModal}
                      onSaveAsFormSubmit={onSaveAsFormSubmit}
                      saveTemplateName={saveTemplateName}
                      setSaveTemplateName={setSaveTemplateName}
                      saveTemplateNameError={saveTemplateNameError}
                      templateLevel={saveAsTemplateLevel} 
                      setTemplateLevel={setSaveAsTemplateLevel}
                      forView={false}
                    />

                    <SaveAsDialog 
                      showSaveAsModal={showSaveViewAsModal}
                      handleCloseSaveAsModal={handleCloseSaveViewAsModal}
                      handleOkSaveAsModal={handleOkSaveViewAsModal}
                      onSaveAsFormSubmit={onSaveViewAsFormSubmit}
                      saveTemplateName={saveViewName}
                      setSaveTemplateName={setSaveViewName}
                      saveTemplateNameError={saveViewNameError}
                      templateLevel={saveAsViewLevel} 
                      setTemplateLevel={setSaveAsViewLevel}
                      forView={true}
                    />

                    <NotSavedDialog 
                      showNotSavedModal={showNotSavedModal}
                      handleCloseNotSavedModal={handleCloseNotSavedModal}
                      handleYesNotSavedModal={handleYesNotSavedModal}
                      handleNoNotSavedModal={handleNoNotSavedModal}
                    />

                    {error && <Errors error={error ? { data: { errors: error}} : boreholeError} />}  
                    
                    {trackData && boreholeData && <TrackData                       
                        clientId={clientId} 
                        projectId={projectId} 
                        boreholeId={boreholeId} 
                        templateId={selectedTemplateId}
                        trackData={trackData} 
                        showView={showView}
                        view={view}
                        sortedTrackData={sortedTrackData}
                        sortedGroupTrackData={sortedGroupTrackData}
                        template={template}
                        startDepth={startDepth} 
                        endDepth={endDepth} 
                        depthUnit={depthUnit}
                        pixelHeight={pixelHeight} 
                        skipLoad={skipLoad} 
                        byBoreholeDepth={byBoreholeDepth}             
                        setDataLoading={setChildLoading}
                        updateCurve={onUpdateCurve}
                        updateCurves={onUpdateCurves}            
                        setShowGridlines={onSetShowGridlines}
                        setScaleType={onSetScaleType}
                        setTrackWidth={onSetTrackWidth}  
                        setViewTrackWidth={onSetViewTrackWidth}                      
                        trackTypes={trackTypesHierarchyData?.trackTypeCategories}
                        editMode={editMode}    
                        showDepthGridLines={template?.showDepthGridLines}
                        depthMajorIntervals={template?.depthMajorIntervals}
                        setDepthMajorIntervals={onSetDepthMajorIntervals}
                        setShowDepthGridlines={onSetShowDepthGridlines}
                        showDepthMinorIntervals={template?.showDepthMinorIntervals}
                        setShowDepthMinorIntervals={onSetShowDepthMinorIntervals}
                        depthMinorIntervals={template?.depthMinorIntervals}
                        setDepthMinorIntervals={onSetDepthMinorIntervals}
                        deletedCurves={deletedCurves}
                        selectedTrackCategory={selectedTrackCategory}
                        setSelectedTrackCategory={setSelectedTrackCategory}
                        trackHeaderClick={onTrackHeaderClick}
                        setMetaDataDepth={onSetMetaDataDepth}
                        deselectAllTracks={deselectAllTracks}
                        selectLithology={onSelectLithology}
                        addLithology={onAddLithology}
                        collapseGroup={onCollapseGroup}
                      /> }
                  </DndProvider>
              </div>
            </div>
          </section>
        </div>
        <div className="right-side-menu-panel" style={{ marginRight: showRightSidePanel ? 427 : 0 }}>
              <button className="button is-tab button-icon" onClick={onShowHideAdjustmentsPanel} title="Close">
                {showRightSidePanel ? <img src={"/images/icons/Atlas Open 2 Icon.svg"} /> : <img src={"/images/icons/Atlas Open 1 Icon.svg"} />}
              </button>
              <button className={`button is-tab button-icon mt-1 ${showAddTrackPanel ? "highlighted" : ""}`} onClick={onShowAddTrackPanel} title="Add Track">
                <img src={"/images/icons/Atlas Add Track Icon.svg"} />
              </button>
              <button className={`button is-tab button-icon mt-1  ${showReorderPanel ? "highlighted" : ""}`} onClick={onSetShowReorderPanel} title="Reorder">
                <img src={"/images/icons/Atlas Reorder Icon.svg"} />
              </button>
              <button className={`button is-tab button-icon mt-1 ${showAdjustmentsPanel ? "highlighted" : ""}`} onClick={onShowAdjustments} title="Adjustments">
                {dataNotSaved.some((x: any) => x) && <span title="Data changed" className="badge"></span>}
                <img src={"/images/icons/Atlas Adjustment Icon.svg"} />
              </button>
              {boreholeData?.showSummaryReport && <button className={`button is-tab button-icon mt-1 ${showAddStatisticsPanel ? "highlighted" : ""}`} onClick={onShowAddStatistics} title="Statistics for report">
                <img src={"/images/icons/calculator-svgrepo-com 2.svg"} />
              </button>}
              {trackTypesHierarchyData?.showBoreholeSelection && <button className={`button is-tab button-icon mt-1 ${showAddBoreholePanel ? "highlighted" : ""}`} onClick={onShowAddBoreholePanel} title="Add Borehole">
                <img src={"/images/icons/Correlation Icon.svg"} />
              </button> }
        </div>
        <div className="right-side-panel" style={{width: showRightSidePanel ? 427 : 0 }}>
          {showAddTrackPanel && 
                <AddTrackPanel                                    
                  onAddTrackFormSubmit={onAddTrackFormSubmit}
                  selectedTrackType={selectedTrackType} 
                  setSelectedTrackType={setSelectedTrackType}
                  trackTypeCategories={trackTypesHierarchyData?.trackTypeCategories} 
                  trackTypesDataIsLoading={trackTypesHierarchyDataIsLoading}                            
                  checkedTrackTypes={checkedTrackTypes}
                  setCheckedTrackTypes={setCheckedTrackTypes} 
                  selectedTemplateTrackType={selectedTemplateTrackType}
                  setSelectedTemplateTrackType={setSelectedTemplateTrackType}
                  checkedImageTypes={checkedImageTypes}
                  setCheckedImageTypes={setCheckedImageTypes}
                  checkedLithologyTypes={checkedLithologyTypes}
                  setCheckedLithologyTypes={setCheckedLithologyTypes}
                  checkedTextTypes={checkedTextTypes}
                  setCheckedTextTypes={setCheckedTextTypes}
                  imageTypes={imageTypes?.imageTypes}
                  selectedTrackCategory={selectedTrackCategory}
                  setSelectedTrackCategory={setSelectedTrackCategory}                  
                  allowedTrackCategories={[0,1,2,3,4,5,6,7,8]}
                  onClose={onHideAddTrackPanel}                  
                />              
            }
              {showAdjustmentsPanel && 
              <GraphAdjustments 
                tracks={sortedTrackData} 
                groups={sortedGroupTrackData}
                showView={showView}
                setSelectedTrack={onSetSelectedTrack} 
                setTrackCollapsed={onSetTrackCollapsed}
                trackTypeCategories={trackTypesHierarchyData?.trackTypeCategories}                
                selectedTrack={selectedTrack}
                selectedGroupId={selectedGroupId}
                updateCurves={onUpdateCurves2}
                setShowGridlines={onSetShowGridlines}                                
                setScaleType={onSetScaleType}
                deleteTrack={onRemoveTrack}
                addCurve={onAddCurve}                
                clientId={clientId}
                changeDataValue={onChangeDataValue}
                saveLithologyData={onSaveLithologyData}
                saveTextData={onSaveTextData}
                changeDepth={onChangeDepth}                
                addDataValue={onAddDataValue}
                addTextValue={onAddTextValue}
                changeTextData={onChangeTextData}
                deleteDataValue={onDeleteDataValue} 
                selectedLithologyId={selectedLithologyId}
                setSelectedLithologyId={setSelectedLithologyId}
                showAdjustmentsPanel={showAdjustmentsPanel}
                setTrackEditMode={onSetTrackEditMode}
                dataNotSaved={dataNotSaved}
                boreholes={boreholes} />
            }           
            {showReorderPanel && <TrackOrderPanel tracks={sortedTrackData} updateTrackOrder={onUpdateTrackOrder} updateGroupTrackOrder={onUpdateGroupTrackOrder} updateGroupOrder={onUpdateGroupOrder} />}
            {showAddStatisticsPanel && <AddStatisticsPanel 
              addStatisticalTrack={onAddStatisticalTrack} 
              deleteStatisticalTrack={onDeleteStatisticalTrack}
              statisticsTracks={template?.statisticsTracks} />}

            {showAddBoreholePanel && 
                <BoreholeCorrelationPanel
                  onAddBoreholeFormSubmit={onAddBoreholeFormSubmit}                  
                  onClose={onHideAddTrackPanel}                  
                  boreholesByProject={boreholesByProject}
                  clientId={clientId}
                  projectId={projectId}
                  boreholeId={boreholeId}
                  tracks={sortedTrackData}
                  addBoreholes={onAddBoreholes}
                  pixelHeight={pixelHeight}
                  startDepth={startDepth}
                  endDepth={endDepth}
                  byBoreholeDepth={byBoreholeDepth}
                  depthUnit={depthUnit}
                />              
            }
        </div>
      </div>
    )
};

export default BoreholeLogViewer;